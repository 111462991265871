import _01 from './01.webp';
import _02 from './02.webp';
import _03 from './03.webp';
import _04 from './04_Fullscreen.webp';
import _05 from './05.webp';
import _07 from './07.webp';

import hoverName from './Hover_Name_5x4.webp';
import hoverOrt from './Hover_Ort_5x4.webp';

export { hoverName, hoverOrt };
export default [
  {
    url: _01,
    title: 'PORTRAIT I',
    fullscreen: false,
    caption: {
      home: 'STEIN',
      canton: 'AR',
      year: '2023',
    },
  },
  {
    url: _02,
    title: 'IM ZIMMER',
    fullscreen: false,
    caption: {
      home: 'STEIN',
      canton: 'AR',
      year: '2023',
    },
  },
  {
    url: _03,
    title: 'DETAIL',
    fullscreen: false,
    caption: {
      home: 'STEIN',
      canton: 'AR',
      year: '2023',
    },
  },
  {
    url: _04,
    title: 'AUF EINEM SPAZIERGANG',
    fullscreen: true,
    caption: {
      home: 'STEIN',
      canton: 'AR',
      year: '2023',
    },
  },
  {
    url: _05,
    title: 'PORTRAIT II',
    fullscreen: false,
    caption: {
      home: 'STEIN',
      canton: 'AR',
      year: '2023',
    },
  },
  {
    url: _07,
    title: 'ROTRAUTS DAMALIGES AUTO',
    fullscreen: false,
    caption: {
      home: 'MÜNZMÜHLE, AI',
      year: '1972',
    },
  },
];
