import React, { useCallback, useState } from 'react';

export const FullscreenPictureContext = React.createContext();

const overlayStyle = {
  height: '100vh',
  width: '100vw',
  backgroundColor: 'rgba(0, 0, 0, 0.9)',
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 100,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

function FullscreenPictureProvider({ children }) {
  const [picture, setPicture] = useState(null);

  const handleSetPicture = useCallback((url) => {
    const body = document.querySelector('body');
    if (url) {
      body.classList.add('stop-scrolling');
    } else {
      body.classList.remove('stop-scrolling');
    }
    setPicture(url);
  });

  return (
    <FullscreenPictureContext.Provider value={handleSetPicture}>
      {picture !== null && (
        <div onClick={() => handleSetPicture(null)} style={overlayStyle}>
          <div style={{
            backgroundImage: `url(${picture})`,
            height: '100%',
            width: '100%',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
          }}
          />
        </div>
      )}
      {children}
    </FullscreenPictureContext.Provider>
  );
}

export default FullscreenPictureProvider;
