/* eslint-disable max-len */
const text1 = `Das Projekt «Vergissmein-Nicht» wirft Licht auf die fast vergessene Praktik der Heimarbeit und die massgebliche Rolle, die Frauen in der Ostschweizer Textilindustrie gespielt haben.

Ein audio-visuelles Archiv vermittelt intime Einblicke in die Geschichte und Alltag ausgewählter Heimarbeiterinnen. Mittels persönlichen Bildmaterials und Gesprächsmitschnitten überliefert «Vergissmein-Nicht» die Lebensrealität dieser Frauen und legt ein besonderes Augenmerk darauf, ihre Personen in den Vordergrund zu stellen. Anhand der Methode der «Oral History» wird eine Annäherung an den früheren Alltag von acht Frauen erwirkt, mit dem Ziel, ihre historische Bedeutung in Industrie und Gesellschaft zu würdigen. In vertrauten Gesprächen werden persönliche Feinheiten ihrer Lebensgeschichten herausgearbeitet und ihr individueller Umgang untersucht. Das Projekt zeigt aus einer feministischen Perspektive, dass eine umfassendere Aufarbeitung von Geschichte notwendig ist und zeigt auf, dass Geschichte performativ ist und stellt Wirklichkeit her durch eine Neuerzählung des Geschehenen.
«Vergissmein-Nicht» ist eine Hommage an die Heimarbeiterinnen, auf deren Rücken die Ostschweizer Textilindustrie aufbaute und folgt dem Ansatz, den leisen Stimmen zuzuhören und möchte den Frauen die Position zusprechen, die ihnen zusteht.

Angesichts des hohen Alters der Frauen drängt die Zeit. Es ist wichtig, ihr kulturelles Erbe und ihre technisches Können zu dokumentieren, bevor es zu spät ist. Durch die Bewahrung und gezielte Verbreitung ihrer Biografien wird sichergestellt, dass ihre Arbeit nicht in Vergessenheit gerät und es wird ein Grundstein gelegt für eine inklusivere und gerechtere Gesellschaft.`;

const text2 = `1761 heirateten der wissbegierige, literarisch interessierte Garn­händler und strenggläubige Pietist Ulrich Bräker (1735 - 1798) der „Arme Mann im Toggenburg“ und die selbstbewusste, etwas herrschsüchtige Analphabetin, Hausfrau und Heimarbeiterin Salome Ambühl (1735 - 1822). Mit dieser Heirat kamen zwei ganz verschiedene Charaktere zusammen. Ulrich Bräker war im Baum­wollgarn Handel tätig. Spinner­innen und einige Weber arbeiteten für ihn. Die Arbeitsbelastung für die sich vergrössernde Familie und die finanzielle Not waren sehr gross. Der Garnhandel konnte, auch bei grosser Anstrengung, keine Familie ernähren.

Bereits in den 1770er Jahren übernahm Salome, das Mädchen mit dem Amazonengesicht, von ihrem Ehe­mann den Garnhandel, da sie nicht an die beruflichen Fähigkeiten ihres Ulrich glaubte. Sie war nun Fergger:in, also in einer Zeit, als dies für eine Frau unüblich war. Selbstbewusst erscheint sie auf dem Bildnis von Joseph Alois Reinhart (1793) in Leinenbluse, blauem Mieder und gestreiftem Rock. Als Attribut ihrer Tätigkeit hat sie ein Bündel Baumwollstrangen unter den linken Arm geklemmt. 

In der zweiten Hälfte des 18. Jahrhunderts war neben Spinnen und Weben auch die Appenzeller Weissstickerei ein wichtiger Erwerbszweig. Die Frauen bestickten im Heimarbeit auf feinstem Musseline Blütengirlanden oder szenische Motive im Auftrag von Fergger:innen und diese arbeiteten für Fabrikanten. Der Verdienst der Handstickerinnen war unentbehrlich für das finanzielle Überleben der Familie in einem einfachen ländlichen Bauernbetrieb. Der Kontakt der Stickerinnen zu den Fergger:innen war eng. Bei speziellen sticktechnischen Begabungen konnten die Stickerinnen Privataufträge erhalten, was sich in der Entlohnung auswirkte. Um für Kundschaft zu werben, konnten einzelne Stickerinnen für kürzere oder längere Aufenthalte in Schweizer - oder ausländische Ferien - oder Kurorte reisen. Die Appenzeller Weissstickerinnen waren selbständige, selbstbewusste Frauen, ohne Amazonengesicht. Viele ihrer Namen sind bekannt. 

Der Anreiz zur Entwicklung der Stickmaschinen waren die Produktionsengpässe für Weissstickereien im 19. Jahrhundert. Von 1850 an wurden daher rund um die Stadt St. Gallen Maschinen montiert – im Appenzell Innerrhoden nur im an den Kanton St. Gallen angrenzenden Bezirk Oberegg. Das ist wohl kein Zufall. Die Arbeit an der Maschine erforderte neu zwei Arbeitskräfte in Teamarbeit. Der Sticker war an der Maschine, die Frau wirkte im Hintergrund. Sie war Fädlerin, Schifflifüllerin, Nachseherin oder Nachstickerin. Arbeitete sie in einem industriellen Betrieb, so war ihre berufliche Ausrichtung möglicherweise Nachseherin, Sekretärin oder vielleicht Entwerferin. Doch ihr Name wurde kaum mit einem bestimmten Stickmotiv in Verbindung gebracht. 

Vereinzelt gab es auch Unternehmerinnen, die im Schatten eines Mannes standen. Oft traute man ihnen den Erfolg nicht zu. Vielleicht hatten diese Frauen doch etwas Kämpferisches? 
Marianne Gächter - Weber, Dipl. Museologin, freie Kuratorin, 2023`;

export { text1, text2 };
