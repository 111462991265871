/* eslint-disable no-param-reassign */
import './App.css';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import useDeviceDetect from './utils/useDeviceDetect';

import Personality from './pages/Personality';
import Projekt from './pages/Projekt';
import Header from './blocks/Header';
import KontaktPage from './pages/Kontakt';
import VideoPage from './pages/Video';

const mobileTheme = createTheme({
  typography: {
    fontFamily: [
      'Chilanka',
      'cursive',
    ].join(','),
  },
  palette: {
    mode: 'dark',
    background: {
      default: '#000000',
    },
  },
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: 'text-regular' },
          style: {
            fontFamily: 'EduDiatype-Mono-Regular',
            fontSize: '13px',
            lineHeight: '13px',
          },
        },
        {
          props: { variant: 'text-bold' },
          style: {
            fontFamily: 'EduDiatype-Bold',
            fontSize: '17px',
            lineHeight: '17px',
          },
        },
      ],
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'video' },
          style: {
            fontFamily: 'EduDiatype-Mono-Regular',
            fontSize: '11px',
            lineHeight: '11px',
            color: 'white',
            minWidth: 0,
          },
        },
      ],
    },
  },
});

const desktopTheme = createTheme({
  typography: {
    fontFamily: [
      'Chilanka',
      'cursive',
    ].join(','),
  },
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: 'text-regular' },
          style: {
            fontFamily: 'EduDiatype-Mono-Regular',
            fontSize: '13px',
            lineHeight: '13px',
          },
        },
        {
          props: { variant: 'text-bold' },
          style: {
            fontFamily: 'EduDiatype-Bold',
            fontSize: '17px',
            lineHeight: '17px',
          },
        },
        {
          props: { variant: 'text-italic' },
          style: {
            fontFamily: 'EduDiatype-BoldItalic',
            fontSize: '17px',
            lineHeight: '17px',
          },
        },
      ],
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'logo-header' },
          style: {
            fontFamily: 'EduDiatype-Mono-Regular',
            padding: 0,
            fontSize: '19px',
            lineHeight: '19px',
          },
        },
        {
          props: { variant: 'header' },
          style: {
            fontFamily: 'EduDiatype-Mono-Regular',
            padding: 0,
            fontSize: '15px',
            lineHeight: '15px',
            minWidth: 0,
          },
        },
        {
          props: { variant: 'personality-info' },
          style: {
            fontFamily: 'EduDiatype-Mono-Regular',
            padding: 0,
            fontSize: '16px',
            lineHeight: '16px',
            minWidth: 0,
          },
        },
        {
          props: { variant: 'audio-chapter' },
          style: {
            fontFamily: 'EduDiatype-Mono-Regular',
            padding: 0,
            fontSize: '17px',
            lineHeight: '17px',
            minWidth: 0,
          },
        },
        {
          props: { variant: 'video' },
          style: {
            fontFamily: 'EduDiatype-Mono-Regular',
            fontSize: '17px',
            lineHeight: '17px',
            color: 'white',
            minWidth: 0,
          },
        },
      ],
    },
  },
  palette: {
    mode: 'light',
    background: {
      default: '#ffffff',
    },
  },
});

function App() {
  const isMobile = useDeviceDetect();
  if (isMobile === null) {
    return null;
  }
  return (
    <BrowserRouter>
      <ThemeProvider theme={isMobile ? mobileTheme : desktopTheme}>
        <CssBaseline />
        <Header />
        <Routes>
          <Route index element={isMobile ? <Personality /> : <VideoPage />} />
          <Route path="index" element={<Personality />} />
          <Route path="projekt" element={<Projekt />} />
          <Route path="kontakt" element={<KontaktPage />} />
        </Routes>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
