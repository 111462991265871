/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';

function SliderImage({ url, fullscreen, smaller = false }) {
  const [aspectRatio, setAspectRatio] = useState(null);

  useEffect(() => {
    if (!fullscreen) {
      const img = new Image();
      img.src = url;

      img.onload = () => {
        setAspectRatio(img.width / img.height);
      };
    }
  }, [url]);

  useEffect(() => {
    if (document.getElementById(url)) {
      const offsets = document.getElementById(url).getBoundingClientRect();
      window.addEventListener('mousemove', (event) => {
        if (event.clientX >= offsets.x && event.clientX <= offsets.x
           + offsets.width && event.clientY >= offsets.y && event.clientY <= offsets.y + offsets.height) {
          document.body.style.cursor = 'move';
        }
      });
    }
  }, [document.getElementById(url)]);

  if (!aspectRatio && !fullscreen) {
    return null;
  }

  return (
    <div
      className={fullscreen ? 'picture-fullscreen' : undefined}
      id={url}
      style={{
        backgroundImage: `url(${url})`,
        backgroundPosition: 'center',
        backgroundSize: fullscreen ? 'cover' : 'contain',
        backgroundRepeat: 'no-repeat',
        width: fullscreen
          ? '100%'
          : `${aspectRatio > 1 ? (smaller ? '25vw' : '33vw') : (smaller ? '35vw' : '50vw')}`,
        height: fullscreen
          ? '100vh'
          : `calc(${aspectRatio > 1 ? (smaller ? '25vw' : '33vw') : (smaller ? '35vw' : '50vw')} * ${aspectRatio})`,
      }}
    />
  );
}

export default SliderImage;
