/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable max-len */
export default <span>
  {`01 Heimarbeit
Rotraut Gründler hatte die tägliche Aufgabe, Stoffe zu übernehmen und sie dann an die Heimarbeiterinnen zu verteilen. Meistens erfolgte die Verteilung in Herisau, an etwa 30 verschiedene Frauen. Die Heimarbeiterinnen waren hauptsächlich Frauen, abgesehen vom Chauffeur.
Diese Aufgabe stellte eine ziemliche Herausforderung dar.

Eine Zeit lang übernahm Rotraut selbst die Rolle des Chauffeurs, da sie Autofahren konnte. Sie belud die Lagefläche ihres Jeeps und fuhr los, um bei den Heimarbeiterinnen vorbeizuschauen und ihnen die Aufträge zusammen mit einem kleinen Muster zu überbringen.

Die Hauptaufgabe der Heimarbeiterinnen bestand darin, die Stickereien zu trennen. Dies taten sie indem sie den oberen Stoff von zwei Stöffen entfernten. Sobald die Frauen ihre Arbeit erledigt hatten, schickte Rotraut den Chauffeur los, um die fertigen Stücke wieder abzuholen. Sie musste genau wissen, wem sie was übergeben konnte. Sie erinnert sich an eine Situation, als der Chauffeur neben ihr stand und sie mit einer ihrer Heimarbeiterinnen sprach. Er erwähnte, wie viel Wissen sie haben müsse, um die Stoffe an die passendste Heimarbeiterin zu verteilen. Es gab Frauen, die besser qualifiziert waren als andere oder Frauen, welche sich im Prozess einer Scheidung befanden, an die sie nicht so viel Arbeit vergeben konnte.

Trotz der geschäftlichen Beziehung entstand zwischen Rotraut Gründler und einer ihrer Heimarbeiterinnen eine innige Freundschaft. Bei jedem Arbeitsplatzwechsel bemühte sie sich, ihre Freundin mitzunehmen. Sie war eine bemerkenswerte Frau griechischen Ursprungs, deren Ehemann ohne Papiere in der Schweiz lebte. Aus Respekt vor ihrer griechischen Tradition hatte sie ihn geheiratet. Diese Frau leistete stets erstklassige Arbeit für Rotraut. Wenn ihr Mann gelegentlich verreisen musste, begleitete sie ihn. Beeindruckend fand Rotraut auch, dass sie in Griechenland ein Haus für vier Familien errichten liess, eines für jedes ihrer vier Kinder.

Die meisten Heimarbeiterinnen nahmen diese Tätigkeit auf, da sie kleine Kinder zuhause hatten. Rotraut Gründler kämpfte unermüdlich dafür, dass sie angemessen entlohnt wurden, und setzte sich dafür ein, dass ihr Ansatz erhöht wurde. Üblicherweise erhielten sie lediglich 1.90 Franken pro Dutzend.

04 Familie
Rotraut Gründlers Ehemann war Grafiker. Doch nachdem er sein eigenes Geschäft eröffnet hatte, bekam er leider gesundheitliche Probleme mit seinem Herzen. Um ihn zu entlasten und sicherzustellen, dass ihre Familie über die Runden kam, schlug sie vor, für ein Jahr lang zu arbeiten. Die Versicherung ihres Mannes reichte nicht aus, um die finanziellen Bedürfnisse zu decken.

So begann Rotraut Gründler zu arbeiten und sie fand grosse Freude an ihrer Tätigkeit. Ihr Chef war jedoch nicht begeistert davon, dass sie manchmal so viele Überstunden leistete. Trotzdem schaffte sie es, ihre Vollzeitstelle auszuüben. Dies war ihr vor allem möglich, weil ihr Mann sein Atelier zuhause hatte und von dort aus arbeiten konnte, was ihr ermöglichte, ausserhalb des Hauses zu arbeiten.
Obwohl der ursprüngliche Plan war, nur ein Jahr lang zu arbeiten, verlängerte sich diese Zeitspanne auf viele weitere Jahre.
Ihr Ehemann übernahm die Aufgabe des Einkaufens und war zudem ein begabter Koch. Er kümmerte sich liebevoll um die Kinder während Rotraut Gründler ihrer beruflichen Tätigkeit nachging.
`}
  <br />

</span>;
