import KontaktMobile from './KontaktMobile';
import useDeviceDetect from '../../utils/useDeviceDetect';
import KontaktDesktop from './KontaktDesktop';

function KontaktPage() {
  const isMobile = useDeviceDetect();
  if (isMobile === null) {
    return null;
  }
  if (isMobile) {
    return <KontaktMobile />;
  }
  return <KontaktDesktop />;
}

export default KontaktPage;
