import _01 from './01.webp';
import _02 from './02.webp';
import _03 from './03.webp';
import _04 from './04.webp';
import _05 from './05.webp';
import _06 from './06.webp';
import _07 from './07_Fullscreen.webp';
import _08 from './08.webp';
import _09 from './09.webp';

import hoverName from './Hover_Name_5x4.webp';
import hoverOrt from './Hover_Ort_5x4.webp';

export { hoverName, hoverOrt };
export default [
  {
    url: _01,
    title: 'BERNADETTE UND LOUISE I',
    fullscreen: false,
    caption: {
      home: 'ST.GALLEN',
      canton: 'SG',
      year: '1966',
    },
  },
  {
    url: _02,
    title: 'BERNADETTES ERSTKOMMUNION',
    fullscreen: false,
    caption: {
      home: 'ABTWIL',
      canton: 'SG',
      year: '1966',
    },
  },
  {
    url: _03,
    title: 'NOTIZZETTEL VON BISCHOFF TEXTIL AG',
    fullscreen: false,
    caption: {
      home: 'ST.GALLEN',
      canton: 'SG',
      year: '1966',
    },
  },
  {
    url: _04,
    title: 'AUSSICHT AUF DEN MAGNOLIENBAUM',
    fullscreen: false,
    caption: {
      home: 'ST.GALLEN',
      canton: 'SG',
      year: '1966',
    },
  },
  {
    url: _05,
    title: 'ABGEFÜLLTES SÄCKLI',
    fullscreen: false,
    caption: {
      home: 'ST.GALLEN',
      canton: 'SG',
      year: '1966',
    },
  },
  {
    url: _06,
    title: 'BORDÜRE VON DAMALS',
    fullscreen: false,
    caption: {
      home: 'ST.GALLEN',
      canton: 'SG',
      year: '1966',
    },
  },
  {
    url: _07,
    title: 'LOUISE UND IHRE MUTTER',
    fullscreen: true,
    caption: {
      home: 'DELÉMONT',
      canton: 'JU',
      year: '1966',
    },
  },
  {
    url: _08,
    title: 'LOUISE MIT IHRER SCHWESTER (LINKS)',
    fullscreen: false,
    caption: {
      home: 'ABTWIL',
      canton: 'SG',
      year: '1966',
    },
  },
  {
    url: _09,
    title: 'BERNADETTE UND LOUISE II',
    fullscreen: false,
    caption: {
      home: 'ST.GALLEN',
      canton: 'SG',
      year: '1966',
    },
  },
];
