import { Button, Grid, Typography } from '@mui/material';
import CloseIcon from '../../../assets/icons/CloseIcon';

function PersonalityOverlay({
  name, desktopTitle, transcription, top, onClose, description,
}) {
  return (
    <Grid
      container
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100vh',
        zIndex: 1001,
        display: 'flex',
        flexDirection: 'column',
      }}
      className="lock-background-scroll"
    >
      <div style={{ height: top, width: '100%', backgroundColor: 'white' }} />
      <Grid
        item
        sx={{
          background: 'linear-gradient(180deg, white 0%, rgba(255,255,255,0.8) 100%)',
          paddingLeft: '26px',
          paddingRight: '26px',
          paddingBottom: '26px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
        flex={1}
      >
        <Grid
          item
          sx={{
            maxWidth: '60vw', display: 'flex', flexDirection: 'column', flex: 1,
          }}
        >
          <div style={{
            width: 420, display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px',
          }}
          >
            <Button onClick={onClose} variant="personality-info"><CloseIcon color="#000" size={13} /></Button>
            <Typography variant="text-bold" sx={{ color: '#9D9D9D' }}>persönliche Angaben:</Typography>
          </div>
          <Typography variant="text-bold">
            {desktopTitle}
          </Typography>
          <Grid
            item
            style={{
              flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end',
            }}
          >
            <Typography
              variant="text-bold"
              sx={{
                display: 'block',
                marginBottom: '5px',
              }}
            >
              (scroll)
            </Typography>
            <div style={{
              width: 420,
              display: 'flex',
              justifyContent: 'flex-end',
              marginBottom: '5px',
            }}
            >
              <Typography variant="text-bold" sx={{ display: 'block', textAlign: 'center' }}>
                Transkription
              </Typography>
            </div>
            <Grid
              item
              style={{ maxHeight: '40vh', overflow: 'auto' }}
              className="hide-scrollbar"
            >
              <Typography
                variant="text-bold"
                sx={{ whiteSpace: 'break-spaces', display: 'block' }}
              >
                {transcription}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          style={{
            width: 153 + 77 + 252 - 26,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
          }}
        >
          <div style={{ width: 77 + 252 - 26, display: 'flex', alignItems: 'flex-end' }}>
            <Typography variant="text-bold" sx={{ color: '#9D9D9D', marginBottom: '5px' }}>
              {name}
            </Typography>
          </div>
          <Typography variant="text-bold" sx={{ color: '#9D9D9D' }}>
            {description}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PersonalityOverlay;
