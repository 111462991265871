import _01 from './01.webp';
import _02 from './02.webp';
import _03 from './03_Fullscreen.webp';
import _04 from './04.webp';
import _05 from './05.webp';
import _06 from './06.webp';

import hoverName from './Hover_Name_5x4.webp';
import hoverOrt from './Hover_Ort_5x4.webp';

export { hoverName, hoverOrt };
export default [
  {
    url: _01,
    title: 'EHEMALIGES ARBEITSZIMMER',
    fullscreen: false,
    caption: {
      home: 'ST.GALLEN',
      canton: 'SG',
      year: '2023',
    },
  },
  {
    url: _02,
    title: 'BORDÜRE VON DAMALS',
    fullscreen: false,
    caption: {
      home: 'ST.GALLEN',
      canton: 'SG',
      year: '2023',
    },
  },
  {
    url: _03,
    title: 'PAUSE AUF DEM BALKON ',
    fullscreen: true,
    caption: {
      home: 'ST.GALLEN',
      canton: 'SG',
      year: '2023',
    },
  },
  {
    url: _04,
    title: 'SÄCKLI VON BISCHOFF TEXTIL',
    fullscreen: false,
    caption: {
      home: 'ST.GALLEN',
      canton: 'SG',
      year: '2023',
    },
  },
  {
    url: _05,
    title: 'AUF EINEM SPAZIERGANG',
    fullscreen: false,
    caption: {
      home: 'ST.GALLEN',
      canton: 'SG',
      year: '2023',
    },
  },
  {
    url: _06,
    title: 'AM KÜCHENTISCH',
    fullscreen: false,
    caption: {
      home: 'ST.GALLEN',
      canton: 'SG',
      year: '2023',
    },
  },
];
