import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import people from '../../people';
import PersonalityDesktopPage from './blocks/PersonalityDesktopPage';
import PersonalitySummary from './blocks/PersonalitySummary';
import CustomCursor from '../../blocks/CustomCursor';
import { CurrentPictureContext } from '../../blocks/CurrentPictureProvider';

function PersonalityDesktop() {
  const navigate = useNavigate();
  const { hash } = useLocation();
  const [currentIndex, setCurrentIndex] = useState(null);
  const [didMount, setDidMount] = useState(false);

  const { picture, totalPictures } = useContext(CurrentPictureContext);

  useEffect(() => {
    setDidMount(true);
  }, []);

  // find current index based on the hash
  useEffect(() => {
    let index = -1;
    if (!hash) {
      index = 0;
    } else {
      const slides = document.querySelectorAll('.personality-slide');
      slides.forEach((slide, key) => {
        if (slide.id === hash.substring(1)) index = key;
      });
    }
    setCurrentIndex(index);
  }, [hash]);

  // when componentDidMount, if hash is null slide to top
  useEffect(() => {
    if (didMount) {
      if (!hash) {
        setCurrentIndex(0);
      }
    }
  }, [didMount, hash]);

  // scroll into view based on currentIndex
  useEffect(() => {
    if (currentIndex !== null) {
      document.querySelectorAll('.personality-slide')[currentIndex].scrollIntoView({ behavior: 'smooth' });
    }
  }, [currentIndex]);

  // thanks again chatgpt
  useEffect(() => {
    const handleScroll = (e) => {
      // if class lock-background-scroll is found, that means that an overlay is on screen
      const test = document.querySelector('.lock-background-scroll');
      // if found, neutralize the scroll behaviour
      if (test) {
        return;
      }
      const slides = document.querySelectorAll('.personality-slide');
      if (e.type === 'keydown' && (e.key === 'ArrowLeft' || e.key === 'ArrowRight')) {
        e.preventDefault();
        const buttonClass = e.key === 'ArrowLeft' ? '.prev-arrow-button' : '.next-arrow-button';
        slides[currentIndex].querySelector(buttonClass)?.click();
      } else {
        let direction;
        if (e.type === 'wheel') {
          e.preventDefault();
          direction = e.deltaY > 0 ? 1 : -1;
        } else if (e.type === 'keydown') {
          if (e.key === 'ArrowUp') {
            e.preventDefault();
            direction = -1;
          } else if (e.key === 'ArrowDown') {
            e.preventDefault();
            direction = 1;
          } else {
            return;
          }
        }

        let nextIndex = currentIndex + direction;
        if (nextIndex < 0) nextIndex = 0;
        if (nextIndex >= slides.length) nextIndex = slides.length - 1;

        setCurrentIndex(nextIndex);
        // change the url hash based on the next person index
        const { id } = document.querySelectorAll('.personality-slide')[nextIndex];
        navigate(`#${id}`);
      }
    };

    window.addEventListener('wheel', handleScroll, { passive: false });
    window.addEventListener('keydown', handleScroll);

    return () => {
      window.removeEventListener('wheel', handleScroll);
      window.removeEventListener('keydown', handleScroll);
    };
  }, [currentIndex]);

  return (
    <>
      <CustomCursor text={`${picture}/${totalPictures}`} />
      <PersonalitySummary people={people} />
      {people.map((p, key) => (
        <PersonalityDesktopPage
          key={p.name}
          name={p.name}
          pictures={p.desktopImages}
          home={p.home}
          canton={p.canton}
          year={p.year}
          desktopTitle={p.desktopTitle}
          transcription={p.transcription}
          description={p.description}
          mp3Chapters={p.mp3Chapters}
          isCurrent={key === currentIndex - 1}
        />
      ))}
    </>
  );
}

export default PersonalityDesktop;
