/* eslint-disable max-len */
function ProjektMobile() {
  return (
    <div style={{
      paddingLeft: '125px', paddingRight: '10px', paddingBottom: '50px', position: 'absolute', marginTop: '60px',
    }}
    >
      <p style={{ fontFamily: 'EduDiatype-Bold', fontSize: '13px', lineHeight: '13px' }}>
        Das Projekt «Vergissmein-Nicht» wirft
        Licht auf die fast vergessene Praktik
        der Heimarbeit und die massgebliche Rolle, die Frauen in der Ostschweizer Textilindustrie gespielt haben.
        <br />
        <br />

        Ein audiovisuelles Archiv vermittelt intime Einblicke in die Geschichte und Alltag ausgewählter Heimarbeiterinnen. Mittels persönlichen Bildmaterials und Gesprächsmitschnitten überliefert «Vergissmein-Nicht» die Lebensrealität dieser Frauen und legt ein besonderes Augenmerk darauf, ihre Personen in den Vordergrund zu stellen.
        <br />
        <br />
        Anhand der Methode der «Oral History» wird eine Annäherung an den früheren Alltag von acht Frauen erwirkt, mit
        dem Ziel, ihre historische Bedeutung
        in Industrie und Gesellschaft zu würdigen. In vertrauten Gesprächen werden persönliche Feinheiten ihrer Lebensgeschichten herausgearbeitet und ihr individueller Umgang untersucht.
        Das Projekt zeigt aus einer feministischen Perspektive, dass eine umfassendere Aufarbeitung von Geschichte notwendig ist und zeigt
        auf, dass Geschichte performativ ist
        und stellt Wirklichkeit her durch eine Neuerzählung des Geschehenen.
        <br />
        <br />

        «Vergissmein-Nicht» ist als Hommage
        zu verstehen an die Heimarbeiterinnen, auf deren Rücken die Ostschweizer
        Textilindustrie aufbaute. Es folgt dem Ansatz, den leisen Stimmen zuzuhören und möchte den Frauen die Position zusprechen, die ihnen zusteht.
        <br />
        <br />

        Angesichts des hohen Alters der Frauen drängt die Zeit. Es ist wichtig, ihr kulturelles Erbe und ihre  technisches
        Können zu dokumentieren, bevor es
        zu spät ist. Durch die Bewahrung und gezielte Verbreitung ihrer Biografien
        wird sichergestellt, dass ihre Arbeit
        nicht in Vergessenheit gerät und es wird ein Grundstein gelegt für eine inklusivere
        und gerechtere Gesellschaft.
      </p>
    </div>
  );
}

export default ProjektMobile;
