import React, { useMemo, useState } from 'react';

export const CurrentPictureContext = React.createContext();

function CurrentPictureProvider({ children }) {
  const [picture, setPicture] = useState(1);
  const [totalPictures, setTotalPictures] = useState(2);

  const value = useMemo(() => ({
    picture, setPicture, totalPictures, setTotalPictures,
  }), [picture, totalPictures]);

  return (
    <CurrentPictureContext.Provider value={value}>
      {children}
    </CurrentPictureContext.Provider>
  );
}

export default CurrentPictureProvider;
