import linaAssets from './assets/01_LINA/assets';
import louisAssets from './assets/02_LOUISE/assets';
import hannaAssets from './assets/03_HANNA/assets';
import rotrautAssets from './assets/04_ROTRAUT/assets';
import normaAssets from './assets/05_NORMA/assets';
import hildegardeAssets from './assets/06_HILDEGARD/assets';
import agnesAssets from './assets/07_AGNES/assets';
import angelicaAssets from './assets/08_ANGELICA/assets';

const people = [
  linaAssets,
  louisAssets,
  hannaAssets,
  rotrautAssets,
  normaAssets,
  hildegardeAssets,
  agnesAssets,
  angelicaAssets,
];

export default people;
