/* eslint-disable max-len */
function CloseIcon({ color = '#fff', size = '9.861' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 9.861 9.861">
      <g id="Groupe_35" data-name="Groupe 35" transform="translate(-19.461 -538.461)">
        <g id="Groupe_10" data-name="Groupe 10" transform="translate(20 539)">
          <g id="Groupe_8" data-name="Groupe 8" transform="translate(0)">
            <path id="Tracé_10" data-name="Tracé 10" d="M.2,0,0,12.225" transform="translate(0 0.139) rotate(-45)" fill="none" stroke={color} strokeWidth="1.5" />
            <path id="Tracé_9" data-name="Tracé 9" d="M0,0,.2,12.225" transform="translate(8.644 0) rotate(45)" fill="none" stroke={color} strokeWidth="1.5" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default CloseIcon;
