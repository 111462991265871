import { Typography } from '@mui/material';

/* eslint-disable max-len */
export default
  <span>
    {`01 Heimarbeit
Hanna Bötschi erinnert sich an die Anfänge ihrer Tätigkeit als Ferggerin`}
    <sup>
      <a href="#footnote_hanna_1">1</a>
    </sup>
    {` bei Bötschi & Co. Die Frauen, die sie betreute, waren schon lange in der Heimarbeit tätig und arbeiteten von zuhause aus, während sie sich gleichzeitig um ihre Kinder kümmern konnten. Bedauerlicherweise sind heute viele von ihnen mittlerweile verstorben, und die genauen Namen sind Hannas Erinnerung entflohen, da sie nicht aufgezeichnet wurden.

Hanna besuchte die Heimarbeiterinnen, brachte ihnen die Materialien und holte die fertigen Aufträge wieder ab. Die Auszahlung erfolgte monatlich und direkt in bar. Die Frauen waren auf Stundenbasis angestellt und notierten ihre Arbeitsstunden, die dann am Monatsende verrechnet wurden. Das Vertrauen zwischen ihnen war gross und es gab keine Probleme mit der Abrechnung. Der Stundenlohn betrug damals 5 Franken, was zwar nicht viel war, aber den Frauen eine gute Möglichkeit bot, von zuhause aus zu arbeiten und sich gleichzeitig um ihre Kinder zu kümmern.

Die Arbeitsdauer variierte je nach Aufwand der einzelnen Aufträge, daher war es nicht möglich, die Heimarbeiterinnen nach der Anzahl der produzierten Objekte zu bezahlen. Die Arbeit erforderte Konzentration und die Frauen hatten zu Hause eine Nähmaschine, um die Fehler aus der Produktion auszubessern. Dies ermöglichte ihnen eine gewisse Flexibilität und erforderte nicht viel Platz.

Hanna besuchte die Heimarbeiterinnen hauptsächlich morgens, um die Arbeit vorbeizubringen, und später, wenn sie fertig waren, um die fertigen Produkte abzuholen. Sie war vor allem für den Transport zuständig und verteilte die Aufträge. Einige der Heimarbeiterinnen arbeiteten sogar bis zum Eintritt in die Altersversorgung, etwa im Alter von 60 bis 65 Jahren.

02 Stickerei
Hanna Bötschi erzählt von den gestickten Stoffen, die für grosse Unternehmen hergestellt wurden. Die Stoffe selbst durften die Bötschi & Co Automatenstickerei nicht verkaufen.
In der Fabrik selbst hatten sie zehn beeindruckende Stickmaschinen. Einer ihrer wichtigen Auftraggeber war Forster Willi. Nachdem der Vater ihres Mannes die Fabrik geleitet hatte, war später ihr Mann dafür verantwortlich, die gestickten Stoffe für die jeweiligen Firmen herzustellen.

Die Fabrik wurde bereits von Hannas Schwiegervater geleitet. Er war für den Betrieb verantwortlich gewesen. Im Haus weiter unten befanden sich zunächst nur zwei Stickmaschinen.

Als die Nachfrage nach Stickerei im Verlauf der Jahre immer grösser wurde, entschied Hannas Schwiegervater ein Haus mit vielen Stickmaschinen zu errichten. Dies war eine bedeutende Entwicklung in der Geschichte des Unternehmens. Das neue Haus symbolisierte den Aufstieg der Stickereiindustrie und bot ihnen die Möglichkeit ihr Geschäft weiter auszubauen.

Hannas Erzählungen lassen die Vergangenheit lebendig werden und vermitteln den Eindruck einer längst vergangenen Ära. Die Stickereiindustrie blühte auf, und die Bötschi & Co Automatenstickerei spielte eine bedeutende Rolle in diesem Bereich.

04 Familie
Während Hanna ihre drei Kinder aufzog, war es für sie glücklicherweise nicht erforderlich, dass sie in der Fabrik mithalf.
In dieser Zeit waren jedoch viele ältere Frauen in der Fabrik beschäftigt und Hanna unterstützte sie gelegentlich, indem sie in die Fabrik hinunterging, um nach dem Rechten zu sehen und den Frauen zur Hand zu gehen.

Als Hanna eines Tages damit beschäftigt war Stoffe zu bündeln, trat ein älterer Herr an sie heran und äusserte Bedenken, dass diese Aufgabe zu schwer für sie und nicht für sie bestimmt sei. Er empfahl ihr, dies ihrem Chef zu melden. Hanna antwortete daraufhin gelassen, dass ihr Chef ihr Ehemann sei und sie es ihm mitteilen werde. Es war Hanna wichtig zu erwähnen, dass sie diese Tätigkeiten aus eigenem Antrieb übernommen hatte. Ihr Mann hatte ihr nie gesagt, dass sie in die Fabrik kommen und mithelfen sollte. Für Hanna war es eine willkommene Abwechslung, wenn ihre Kinder in der Schule waren, und sie ergriff diese Möglichkeit gerne. Es war für sie eine Option aktiv zu sein und sich einzubringen.

Trotz ihrer Rolle als Ehefrau und Mutter erhielt Hanna auch einen Lohn. Sie hatte ihrem Mann mitgeteilt, dass sie ebenfalls einen Lohn erhalten wollte, nicht weil sie ihn dringend benötigte, sondern weil sie den gleichen Lohn wie die Heimarbeiterinnen erhalten wollte. Es war für sie eine Frage der Gleichstellung und Anerkennung ihrer Arbeit.
`}
    <br />
    <Typography variant="text-italic" id="footnote_hanna_1">
      1. Ferggerin: Als Ferger:in versteht sich eine Person, die in der vermittelnden Position zwischen Fabrikant:in und Sticker:in steht. Der/Die Ferger:in ist zuständig für die Verteilung der Aufträge der Fabrikanten an die Sticker:innen und bestimmt welche Aufträge der jeweiligen Sticker:in zugeteilt wird.
    </Typography>
  </span>;
