import _01 from './01.webp';
import _02 from './02.webp';
import _03 from './03.webp';
import _04 from './04.webp';
import _05 from './05.webp';
import _06 from './06.webp';
import _07 from './07.webp';
import _08 from './08.webp';
import _09 from './09_Fullscreen.webp';
import _10 from './10.webp';
import _11 from './11.webp';
import _12 from './12.webp';

import hoverName from './Hover_Name_5x4.webp';
import hoverOrt from './Hover_Ort_5x4.webp';

export { hoverName, hoverOrt };
export default [
  {
    url: _01,
    title: 'PORTRAIT I',
    fullscreen: false,
    caption: {
      home: 'GONTENBAD',
      canton: 'AI',
      year: '2023',
    },
  },
  {
    url: _02,
    title: 'IM WOHNZIMMER',
    fullscreen: false,
    caption: {
      home: 'GONTENBAD',
      canton: 'AI',
      year: '2023',
    },
  },
  {
    url: _03,
    title: 'DETAIL',
    fullscreen: true,
    caption: {
      home: 'GONTENBAD',
      canton: 'AI',
      year: '2023',
    },
  },
  {
    url: _04,
    title: 'HILDEGARD IN DER SONNTAGSTRACHT',
    fullscreen: false,
    caption: {
      home: 'GONTENBAD',
      canton: 'AI',
      year: '1962',
    },
  },
  {
    url: _05,
    title: 'HILDEGARDS KÜCHENTISCH',
    fullscreen: false,
    caption: {
      home: 'GONTENBAD',
      canton: 'AI',
      year: '2023',
    },
  },
  {
    url: _06,
    title: 'HILDEGARD MIT IHREM MANN BEI DER ARBEIT',
    fullscreen: false,
    caption: {
      home: 'GONTENBAD',
      canton: 'AI',
      year: '1962',
    },
  },
  {
    url: _07,
    title: 'HILDEGARD MIT IHREr MUTTER',
    fullscreen: false,
    caption: {
      home: 'GONTENBAD',
      canton: 'AI',
      year: '1943',
    },
  },
  {
    url: _08,
    title: 'DAS ERSTE MAL FONDUE',
    fullscreen: false,
    caption: {
      home: 'LONDON',
      year: '1969',
    },
  },
  {
    url: _09,
    title: 'PORTRAIT II',
    fullscreen: true,
    caption: {
      home: 'GONTENBAD',
      canton: 'AI',
      year: '2023',
    },
  },
  {
    url: _10,
    title: 'AUSSICHT VOM BALKON',
    fullscreen: false,
    caption: {
      home: 'GONTENBAD',
      canton: 'AI',
      year: '2023',
    },
  },
  {
    url: _11,
    title: 'DECKELI FÜR DEN MASCHINENSTOCK',
    fullscreen: false,
    caption: {
      home: 'GONTENBAD',
      canton: 'AI',
      year: '2023',
    },
  },
  {
    url: _12,
    title: 'HILDES NADELETUI',
    fullscreen: false,
    caption: {
      home: 'GONTENBAD',
      canton: 'AI',
      year: '2023',
    },
  },
];
