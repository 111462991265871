import { Grid } from '@mui/material';

const text1Style = {
  fontFamily: 'EduDiatype-Mono-Regular',
  fontSize: '13px',
  textTransform: 'uppercase',
  lineHeight: '13px',
  color: '#9D9D9D',
  marginBottom: '-5px',
};

const text2Style = {
  fontFamily: 'EduDiatype-Mono-Regular',
  fontSize: '13px',
  textTransform: 'uppercase',
  lineHeight: '13px',
  marginTop: '8px',
};

function KontaktDesktop() {
  return (
    <Grid
      container
      marginTop={`${26 + 22}px`}
    >
      <Grid item sx={{ display: 'flex', width: '100%', alignItems: 'flex-start' }}>
        <div style={{
          flexGrow: 1,
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          marginRight: 64,
        }}
        >
          <div>
            <p style={text1Style}>
              MITWIRKEN
            </p>
            <p style={text2Style}>
              Wenn Sie, Ihre Mutter oder
              andersweite
              <br />
              Verwandte in der
              textilen Geschichte
              <br />
              {' '}
              der Ostschweiz
              mitgearbeitet haben,
              <br />
              {' '}
              würden wir uns
              freuen von Ihnen zu hören
            </p>
            <p style={text1Style}>
              KONTAKT
            </p>
            <p style={text2Style}>
              bitte kontaktieren Sie uns
              <br />
              via
              {' '}
              <a href="mailto:celine.hess@gmail.com" style={{ textDecoration: 'underline' }}>Mail</a>
            </p>
          </div>
        </div>
        <div style={{ width: 153 + 77 + 252 }}>
          <p style={text1Style}>
            UMSETZUNG
          </p>
          <p style={text2Style}>
            Céline HESS
            <br />
            Loana Lenz
          </p>
          <p style={text1Style}>
            MENTOR:INNEN
          </p>
          <p style={text2Style}>
            Henriette-Friederike Herm
            <br />
            Markus Bucher
          </p>
          <p style={text1Style}>
            EXPERT:INNEN
          </p>
          <p style={text2Style}>
            Christof Täschler
            <br />
            Hansruedi Rohrer
            <br />
            Marianne Gächter
            <br />
            Roman Wild
          </p>
          <p style={text1Style}>
            CODE
          </p>
          <p style={text2Style}>
            Clément Richet
            <br />
            Florent Béjina
          </p>
          <p style={text1Style}>
            UNTERSTÜTZUNG
          </p>
          <p style={text2Style}>
            Appenzeller-Museum
            <br />
            Frauenarchiv Ostschweiz
            <br />
            Museum Herisau
            <br />
            Ortsmuseum Flawil
            <br />
            Textilmuseum St.Gallen
          </p>
          <p style={text1Style}>
            MITWIRKENDE
          </p>
          <p style={text2Style}>
            Lina Bischofberger
            <br />
            Louise Jeger
            <br />
            Hanna Bötschi
            <br />
            Rotraut Gründler
            <br />
            Norma Penasa
            <br />
            Hildegard Koch-Rusch
            <br />
            Agnes Rüegg-Rütsche
            <br />
            Angelica Pfister
          </p>
          <p style={text1Style}>
            DIPLOMARBEIT 2023
          </p>
          <p style={text2Style}>
            Zürcher Hochschule der Künste
          </p>
        </div>

      </Grid>
    </Grid>
  );
}

export default KontaktDesktop;
