const containerStyle = {
  paddingLeft: '45%', paddingRight: '10px', paddingBottom: '50px', position: 'absolute', marginTop: '60px',
};
const text1Style = {
  fontFamily: 'EduDiatype-Mono-Regular',
  fontSize: '12px',
  textTransform: 'uppercase',
  color: '#9D9D9D',
  marginBottom: '-13px',
};

const text2Style = {
  fontFamily: 'EduDiatype-Mono-Regular',
  fontSize: '12px',
  textTransform: 'uppercase',
};

function KontaktMobile() {
  return (

    <div style={containerStyle}>
      <p style={text1Style}>
        UMSETZUNG
      </p>
      <p style={text2Style}>
        Céline HESS
        <br />
        Loana Lenz
      </p>
      <p style={text1Style}>
        MENTOR:INNEN
      </p>
      <p style={text2Style}>
        Henriette-Friederike Herm
        <br />
        Markus Bucher
      </p>
      <p style={text1Style}>
        EXPERT:INNEN
      </p>
      <p style={text2Style}>
        Christof Täschler
        <br />
        Hansruedi Rohrer
        <br />
        Marianne Gächter
        <br />
        Roman Wild
      </p>
      <p style={text1Style}>
        CODE
      </p>
      <p style={text2Style}>
        Clément Richet
        <br />
        Florent Béjina
      </p>
      <p style={text1Style}>
        UNTERSTÜTZUNG
      </p>
      <p style={text2Style}>
        Appenzeller-Museum
        <br />
        Frauenarchiv Ostschweiz
        <br />
        Museum Herisau
        <br />
        Ortsmuseum Flawil
        <br />
        Textilmuseum St.Gallen
      </p>
      <p style={text1Style}>
        MITWIRKEN
      </p>
      <p style={text2Style}>
        Wenn Sie, Ihre Mutter oder andere Verwandte in der textilen Geschichte
        der Ostschweiz mitgearbeitet haben,
        würden wir uns freuen,
        von Ihnen zu hören
      </p>
      <p style={text1Style}>
        KONTAKT
      </p>
      <p style={text2Style}>
        bitte kontaktieren Sie
        uns via
        {' '}
        <a href="mailto:celine.hess@gmail.com" style={{ textDecoration: 'underline' }}>Mail</a>
      </p>
      <p style={text1Style}>
        gekürzte version
      </p>
      <p style={text2Style}>
        Die mobile version dient als teaser für die
        desktop variante
      </p>
    </div>
  );
}

export default KontaktMobile;
