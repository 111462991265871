import { Typography } from '@mui/material';

/* eslint-disable max-len */
export default
  <span>
    {`01 Heimarbeit
Lina Bischofsberger übte das Handmaschinensticken`}
    <sup>
      <a href="#footnote_lina_1">1</a>
    </sup>
    {' bis zu ihrem 92. Lebensjahr aus. Heutzutage wird dieses Handwerk jedoch nicht mehr als geeignete Verdienstmöglichkeit angesehen. Für sie persönlich funktionierte es jedoch gut, weil sie zusätzlich von der Alters- und Hinterbliebenenversicherung (AHV) eine willkommene Unterstützung erhielt. Mit 92 Jahren bemerkte sie jedoch, dass ihre Kräfte nicht mehr die gleichen waren wie früher und sie Schwierigkeiten hatte, die gleichen Arbeiten auszuführen. Trotzdem ging es ihr gut, denn sie hatte das Privileg, viele Besuchergruppen aus dem Ausland zu führen. In dieser Zeit gelang es ihr sogar, den Besuchern ihre kunstvoll bestickten Tüchli'}
    <sup>
      <a href="#footnote_lina_2">2</a>
    </sup>
    {` zu verkaufen. Als die Bischoff Textil AG die Produktion einstellte, war sie die letzte Handstickerin für das Unternehmen. Sie konnte noch grosse Aufträge bewältigen. Als Bischoff schloss, übernahm sie das gesamte Lager, einschliesslich tausender Garnspulen und Musterzeichnungen.

Lina Bischofberger und ihr Mann hatten es geschafft, sich ihren Lebensunterhalt mit der Heimarbeit zu verdienen. Sie hatten zwei Stickmaschinen zur Verfügung. Während ihr Mann auf einer Maschine stickte, bediente sie die andere. Zusätzlich half Lina ihrem Mann geduldig beim «ihfädle»`}
    <sup>
      <a href="#footnote_lina_3">3</a>
    </sup>
    {`. Sie erledigte beharrlich eine Aufgabe nach der anderen, ohne Pause. Von frühmorgens bis spät abends arbeitete sie unermüdlich. Disziplin begleitete sie auf Schritt und Tritt. Um die Mittagszeit, gegen zwölf Uhr, gönnten ihr Mann und sie sich eine Stunde Ruhe, um neue Energie zu tanken. Pünktlich um 13 Uhr setzte ihr Mann seine Arbeit fort und begann erneut zu sticken. So waren die Zeiten der Heimarbeit damals. Doch diese Tage sind längst vergangen, heutzutage würden die Menschen solche Arbeitsbedingungen nicht mehr akzeptieren. Es ist eine vergangene Ära.

Erst als ihr Mann seine erste AHV`}
    <sup>
      <a href="#footnote_lina_4">4</a>
    </sup>
    {` erhielt, wagten sie es, sich eine Auszeit zu gönnen. Sie genossen einen entspannenden Urlaub in einer Ferienwohnung der Reka. Mit leichtem Gepäck zogen sie von Ort zu Ort und kochten ihre Mahlzeiten selbst. Wenn sie ihrem Schwiegersohn davon erzählte, scherzte er: "Ihr habt selbst in den Ferien gespart, oder?"

Aufgrund des hohen Alters ihres Mannes beschlossen sie, erst dann Urlaub zu machen, sobald ihre Kinder ihre Ausbildung abgeschlossen hatten und auf eigenen Füssen standen.
Lina selbst hatte von 1939 bis 1941 die Sekundarschule in Mohren besucht. Noch heute ist sie dankbar, dass ihre Eltern sie nach Altstätten in die Schule geschickt hatten.

02 Stickerei
Lina Bischofsberger erlernte das Sticken von ihrem Ehemann, als sie 24 Jahre alt war. Von Anfang an half sie ihm tatkräftig mit. Beim Sticken ging es nicht nur darum, die eigentlichen Muster zu besticken, sondern auch darum, die 104 Tücher auf der Stickmaschine einzuspannen. Diese Tücher mussten einzeln in die Rahmen gesteckt werden, was viel Vorarbeit erforderte. Jedes Tuch hatte eine eigene Nadel mit einem eigenen eingefädelten Faden. Wenn der Faden aufgebraucht war, mussten alle 104 Fäden abgeschnitten werden, denn anders als bei Schifflistickmaschinen`}
    {' '}
    <sup>
      <a href="#footnote_lina_5">5</a>
    </sup>
    {' gab es keine Spulen'}
    <sup>
      <a href="#footnote_lina_6">6</a>
    </sup>
    . Anschliessend wurden die Nadeln gereinigt, erneut eingefädelt und jeder Klupper
    <sup>
      <a href="#footnote_lina_7">7</a>
    </sup>
    , der die Nadeln hält, erhielt eine neue Nadel, um weiter zu sticken. Diese Arbeit erforderte stets Linas Unterstützung in Form der zusätzlichen Zuarbeit
    <sup>
      <a href="#footnote_lina_8">8</a>
    </sup>
    {`. Nach dem Tod ihres Mannes führte sie diese Arbeit allein fort. Es wäre für sie nicht rentabel gewesen, jemanden zusätzlich einzustellen. Wenn man als Familie jedoch von der Stickerei in der Heimarbeit leben wollte, mussten beide Elternteile mithelfen.

Vor ungefähr 35 Jahren war Lina Bischofsberger die Ausbildnerin von Monika Bollhalder aus Unterwassen im Bereich des Handmaschinenstickens`}
    {` Bischoff Textil hatte sie damals angefragt, ob Monika von ihr das Sticken erlernen könnte. Zu dieser Zeit existierte noch die Stickfachschule in St. Gallen-Bruggen, doch die Maschine dort war bereits belegt. Deshalb wurde Lina gebeten, den Unterricht zu übernehmen. Monika arbeitete anschliessend auch für Bischoff Textil, doch mit der Zeit wurden immer weniger Aufträge vergeben. Aus diesem Grund entschied sich Monika dazu, aufzuhören, durfte aber die Maschine behalten. Sie konnte weiterhin selbständig arbeiten, erzielte damit jedoch nicht viel Einkommen. Deswegen führte sie zusätzlich auch andere Arbeiten aus.

Die Bezahlung erfolgte nach Anzahl der Stiche. Es war den Stickenden überlassen, zu berechnen, wie viele Stiche ein Muster enthielt, wie viel Faden dafür benötigt wurde, und wie oft der Faden erneut eingefädelt werden musste. Letztendlich bestimmte das Ergebnis den Preis. Wenn Lina ein neues Muster erhielt, das sie zuvor noch nie gestickt hatte, startete sie zunächst mit ihrem Grundtarif und berechnete dann zusätzliche Aufschläge basierend auf den verschiedenen Faktoren. Diese Berechnungen kalkulierte sie selbst, da dies ihre Aufgabe war. Jeden Mittwoch kam der Fergger`}
    <sup>
      <a href="#footnote_lina_9">9</a>
    </sup>
    {` vorbei, der die Ware lieferte, abholte und manchmal auch Reklamationen mit sich brachte. Aber auch die Sticker:innen konnten sich bei ihm beschweren, wenn der Preis nicht stimmte. In solchen Fällen wurden Diskussionen über den Preis geführt und es wurde fachgesimpelt.

Es erfüllt sie nun mit Freude, letztendlich im Altersheim in Reute zu verweilen. Im kommenden Sommer wird sie das stolze Alter von 99 Jahren erreichen. Nun ist der Zeitpunkt für sie gekommen, wo sie keine Verpflichtungen mehr hat und sich ganz dem gegenwärtigen Moment hingeben kann. Ihre Hände finden nun ihren kreativen Ausdruck in der Kunst des "Lismens" – dem Stricken. Statt sich der Stickerei zu widmen, hat sie nun eine Strickerei ins Leben gerufen. Dieses neue Kapitel bereitet ihr grosse Freude und sie kann ihre handgefertigten Werke erfolgreich verkaufen. Mit den Erlösen unterstützt sie das Altersheim und schenkt ihren Mitbewohnenden etwas Besonderes.

04 Familie
In Mohren wuchs Lina Bischofberger umgeben von ihren drei Geschwistern auf. Im Jahr 1948 heiratete sie und wurde Mutter von zwei Töchtern. Ihre Töchter verbrachten ihre Kindheit in einem dunkelbraunen Appenzellerhaus, nicht weit entfernt vom Altersheim, das nun Linas Zuhause ist. Seit 1988 ist sie verwitwet.

Ihr Mann war Handsticker und aufgrund der finanziellen Mittel lernte sie, ihm zur Hand zu gehen. Aufgrund des hohen Altersunterschiedes zwischen ihr und ihrem Mann, wollte Lina sicherstellen, dass sie auch alleine zurechtkommen könnte, falls ihm etwas zustossen sollte und sie mit den Kindern alleine wäre. Glücklicherweise kam es nie dazu, und ihre Kinder wuchsen heran und zogen aus.

Im Jahr 1979 erlitt ihr Mann einen Hirnschlag. Daraufhin pflegte sie ihn bis zu seinem Tod im Jahr 1988. Während dieser Zeit setzte sie ihre Stickarbeit für Bischoff Textil in St. Gallen fort.

Ihre Kinder wuchsen im Sticklokal`}
    <sup>
      <a href="#footnote_lina_10">10</a>
    </sup>
    {` auf, wo sie auch viele Stunden mit Spielen verbrachten. Ihr Mann begann jeden Morgen um sechs Uhr mit dem Sticken, und Lina sorgte dafür, dass das Frühstück vorbereitet und die Kinder versorgt waren. Später, wenn die Kinder in der Schule waren und ihre Zuhilfsarbeit erledigt war, kümmerte sie sich um den Haushalt.

Das Leben brachte seine Herausforderungen mit sich, aber Lina Bischofberger betont immer wieder: "Ich würde immer wieder ja sagen."
Es war wichtig für sie, etwas zu tun zu haben. Ihre Finger fanden Trost und Erfüllung in der Stickerei, und sie war stolz darauf, ihre Kunstfertigkeit einsetzen zu können.

05 Politik
Im Jahr 1948 wurde die Alters- und Hinterbliebenenversicherung (AHV) eingeführt. Lina erinnert sich noch gut daran, wie sich ihr Mann über das folgende Szenario aufgeregt hatte: In Reute befand sich eine Stickereifabrik, die von einem gewissen Albert Keller geleitet wurde. In dieser Fabrik beschäftigte er Schulmädchen als Angestellte und ermutigte sie alle dazu, gegen die AHV-Abstimmung zu stimmen. Dieser Umstand hatte Linas Mann stets geärgert. Denn er war für die AHV gewesen, beide waren der Meinung, dass dies ein bedeutendes Sozialwerk sei.
Natürlich haben sich die Bedingungen für die Rentenversicherung seitdem verändert, aber damals war es eine bedeutende Errungenschaft für sie. Sie erhielten monatlich 511 Franken, was eine beträchtliche Summe für sie darstellte. Zusätzlich hatten sie noch die Möglichkeit, weiterhin ihre Stickarbeiten auszuüben. Wenn sie jungen Menschen von damals erzählen, können diese es kaum noch glauben.

An Samstagen stickten sie bis 16:00 Uhr, anstatt bis 20:00 Uhr.
    `}
    <br />
    <Typography variant="text-italic" id="footnote_lina_1">
      1. Handmaschinensticken: Die Handmaschinenstickerei ist eine Technik, bei der spezielle handbetriebene Maschinen verwendet werden, um Muster auf Stoff zu sticken. Im Gegensatz zur Handstickerei erfolgt die Stickerei hier schneller und effizienter. Die Technik ermöglicht eine Vielfalt an Stichen und Designs und wird in der Modeindustrie, bei Heimtextilien und für kunstvolle Stickereien verwendet.
    </Typography>
    <br />
    <br />
    <Typography variant="text-italic" id="footnote_lina_2">
      2. Tüchli: Ein kleines Stück Stoff, das traditionell in der Schweiz bestickt wird. Die Stickerei auf dem Tüchli ist oft sehr filigran und detailreich und zeigt verschiedene Muster und Motive.
    </Typography>
    <br />
    <br />
    <Typography variant="text-italic" id="footnote_lina_3">
      3. ihfädle: Das Einfädeln von Faden in die Nadeln, die danach in die Maschine eingeführt werden.
    </Typography>
    <br />
    <br />
    <Typography variant="text-italic" id="footnote_lina_4">
      4. erste AHV: Am 1. Januar 1948 wurde die AHV schließlich in Kraft gesetzt und die ersten Renten wurden ausgezahlt.
    </Typography>
    <br />
    <br />
    <Typography variant="text-italic" id="footnote_lina_5">
      5. Schifflistickmaschine: Eine Schifflistickmaschine ist eine spezielle Stickmaschine, die für das Schifflisticken verwendet wird. Diese traditionelle Sticktechnik wurde im 19. Jahrhundert entwickelt.
    </Typography>
    <br />
    <br />
    <Typography variant="text-italic" id="footnote_lina_6">
      6. Spule: Eine Spule ist eine kleine, zylindrische Vorrichtung, die mit Faden oder Garn gefüllt ist und in einer Stickmaschine oder Handstickerei verwendet wird. Die Spule dient dazu, den Faden während des Stickvorgangs zu speichern und gleichmässig abzugeben.
    </Typography>
    <br />
    <br />
    <Typography variant="text-italic" id="footnote_lina_7">
      7. Klupper: Der Klupper hält die Nadel damit diese durch den Stoff gezogen werden kann.
    </Typography>
    <br />
    <br />
    <Typography variant="text-italic" id="footnote_lina_8">
      8. Zuarbeit: Die Zuarbeit ist ein wichtiger Bestandteil des Stickens. Die Arbeit bezieht sich auf das Vorbereiten und Nachbearbeiten gewisser notwendiger Aufgaben, wie zum Beispiel, das Einfädeln von Fäden, Rahmen, die gespannt werden müssen oder das Putzen der Werkzeuge.
    </Typography>
    <br />
    <br />
    <Typography variant="text-italic" id="footnote_lina_9">
      9. Fergger:in: Als Ferger:in versteht sich eine Person, die in der vermittelnden Position zwischen Fabrikant:in und Sticker:in steht. Der/Die Ferger:in ist zuständig für die Verteilung der Aufträge der Fabrikanten an die Sticker:innen und bestimmt welche Aufträge der jeweiligen Sticker:in zugeteilt wird.
    </Typography>
    <br />
    <br />
    <Typography variant="text-italic" id="footnote_lina_10">
      10. Sticklokal: Eine Räumlichkeit, in der eine Stickmaschine steht.
    </Typography>
  </span>;
