import mobileImages from './mobile/assets';
import desktopImages, { hoverName, hoverOrt } from './desktop/assets';
import description from './description';
import transcription from './transcription';
import mp3 from './mp3/assets';

const name = 'Louise Jeger';
const home = 'Abtwil';
const canton = 'SG';
const year = '1932';
const mobileTitle = `${year}
${home} ${canton}
geschieden mit 2 Kindern
Heimarbeiterin
Bischoff Textil AG / Forster Rohner AG`;
const desktopTitle = [name, ...mobileTitle.split('\n')].join(', ');

export default {
  name,
  home,
  canton,
  year,
  mobileTitle,
  desktopTitle,
  mobileImages,
  hoverName,
  hoverOrt,
  desktopImages,
  description,
  transcription,
  ...mp3,
};
