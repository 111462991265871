import _01 from './01.webp';
import _02 from './02.webp';
import _03 from './03.webp';
import _04 from './04.webp';
import _05 from './05.webp';
import _06 from './06.webp';
import _07 from './07.webp';
import _08 from './08.webp';
import _09 from './09.webp';
import _10 from './10.webp';
import _11 from './11.webp';
import _12 from './12.webp';
import _13 from './13.webp';

export default [
  _01,
  _02,
  _03,
  _04,
  _05,
  _06,
  _07,
  _08,
  _09,
  _10,
  _11,
  _12,
  _13,
];
