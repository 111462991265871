function P({ children, style = {} }) {
  return (
    <p style={{
      fontFamily: 'EduDiatype-Bold',
      fontSize: '12px',
      ...style,
    }}
    >
      {children}
    </p>
  );
}

export default P;
