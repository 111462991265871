/* eslint-disable react/jsx-indent */

import { Typography } from '@mui/material';

/* eslint-disable max-len */
export default <span>
  {`01 Heimarbeit
Als Normas Mann erkrankte, führte sie in ihrer alten Wohnung die Heimarbeit aus. Sie arbeitete für die Forster Willi AG, welche unter anderem Tischdecken für Paris herstellte.
Für ein paar Stunden am Stück konnte sie arbeiten während ihre Kinder manchmal die Nadeln am Boden zusammensuchten.
Patrizia, Normas Tochter erinnert sich, dass ihre Mutter manchmal auch Motive ausschneiden musste. Immer im Dutzend mussten diese abgezählt und in kleine Beutel abgefüllt werden.
Am Abend versammelte sich die ganze Familie und als Patrizia ein Kind war, half sie auch mit die Motive`}
<sup>
<a href="#footnote_norma_1">1</a>
</sup>
{` abzuzählen.

Doch solche Momente waren nicht alltäglich. Diese fanden nur statt, wenn keine anderen Stickarbeiten anstanden, denn dies war nicht Normas Hauptbeschäftigung.

Vergleichbar ist dies mit dem Home Office, wenn man es ins Heute übersetzt. Nur war es damals eine unerhört unterbezahlte Tätigkeit.

Norma Penasa genoss es jedoch, diese Arbeit zu verrichten. Ihr wurden 15 Meter edler Leinenstoff überreicht, für den eigentlich 20 Stunden für die Bearbeitung vorgesehen waren. Doch bereits nach 15 Stunden war sie stolz auf ihr vollendetes Werk.

Patrizia erwähnt, dass ihre Mutter diese Tätigkeit mit grosser Hingabe ausführte und dabei einen ungeheuren Stolz empfand. Es gab Modekataloge die, die Haute Couture`}
<sup>
<a href="#footnote_norma_2">2</a>
</sup>
{` aus Paris zeigten, die nach den glamourösen Modenschauen zu ihr nach Hause gelangten. Strahlend zeigte sie den Kindern: "Sieh, das habe ich gestickt."
Für sie war es, als ob sie selbst Teil dieser aufregenden Welt wäre. Daher hegte sie solch eine tiefe Zuneigung für diese Arbeit. Oftmals erhielt sie Anrufe, in denen man ihr mitteilte, dass die Ware am Nachmittag oder im Verlauf des Vormittags vorbeigebracht würde und sie sie bis zum nächsten Morgen fertigstellen sollte. Obwohl sie eigentlich bereits offiziell aufgehört hatte, erhielt sie eilige Aufträge. Und wenn die Lieferung nicht pünktlich eintraf, ärgerte sie sich und überlegte, ob sie nun die ganze Nacht durcharbeiten sollte, um die Ware rechtzeitig fertigzustellen. Doch trotz allem nahm sie die Arbeit entgegen und stellte die Ware immer pünktlich fertig. Durch ihren Eifer und ihre Willenskraft konnte sie sich stundenlang in ihrer Heimarbeit verlieren.
An dieser Stelle ist zu erwähnen, dass man die Heimarbeiterinnen damals wirklich ausgenutzt hat.

Norma erhielt einen Stundenlohn für ihre Tätigkeit. Wenn für 15 Meter Stoff eigentlich 20 Stunden Arbeit vorhergesehen waren, wurde sie für diese 20 Stunden bezahlt. Sie strebte aber immer danach, ihre Zeit effizienter zu nutzen und mit weniger Stundeneinsatz fertig zu werden, um somit den Stundenlohn zu erhöhen. Es gab jedoch auch Projekte, bei denen sie länger brauchte als vorgesehen, jedoch durfte sie keine Überstunden geltend machen. Sie musste selbst auf die Zeit achten, während sie von zuhause aus arbeitete, um danach noch andere Verpflichtungen erledigen zu können. Wenn sie schneller arbeitete, rentierte sich die Heimarbeit für Norma mehr. Wer jedoch nicht schnell genug war, für den rentierte sich die Heimarbeit eher weniger.

Im Laufe der Zeit ist Norma die genaue Höhe ihres Stundenlohns entfallen. Ihre Tochter erinnert sich jedoch daran, dass es wahrscheinlich zwischen 13 und 16 Franken waren. Norma kannte nicht viele Frauen, die diese Art von Arbeit von zuhause aus ausgeführt wurde. Die anderen Frauen, die sie kannte, haben zwar ähnliche Tätigkeiten wie das Zuschneiden ausgeführt, aber sie haben nicht von zuhause aus genäht, so wie Norma es getan hat. Sie erinnert sich: "Aber ich habe es schon gerne gemacht."

"Forster Willi: Frau Penasa ist fertig.“


02 Stickerei
«Nein, nein, ich musste Nachsticken`}
<sup>
<a href="#footnote_norma_3">3</a>
</sup>
{`».
Das bedeutet, dass sie die Stickerei reparieren oder verbessern musste.
Einmal habe sie einen Silberstoff bekommen, einen Meter lang, mit Silberfaden. Für die Marke Valentino aus Italien. Ein Meter von diesem edlen Stoff hatte 900 Franken gekostet. Dort habe sie auch Überstunden geleistet. Es war mühsame Arbeit, für die sie zusammennähen musste. Sie habe die einzelnen Stücke zusammengefügt und zusammengenäht.

Für jeden Meter habe sie geschaut, wie viel Zeit sie brauche.

Norma war auch dafür verantwortlich, Muster zu erstellen. Sie nähte zuhause ein Muster zusammen und notierte sich die dafür benötigte Zeit. Diese Zeit diente als Massstab für alle Heimarbeiterinnen und war entscheidend für die Festlegung der Arbeitszeit und des Preises für einen bestimmten Stoff. Norma spielte somit eine wichtige Rolle bei der Bestimmung der Zeit- und Preisrichtlinien für die Arbeit von Heimarbeiterinnen.

03 Jugendzeit
In jungen Jahren verliess Norma Penasa Italien, nicht wie viele andere Migranten aufgrund der bitteren Arbeitslosigkeit, sondern als eine alleinerziehende Mutter. Patrizia erwähnt, wie schrecklich es in Italien im Jahr 1985 war, ein uneheliches Kind zu haben, und so führte Normas Weg in die Schweiz.
Norma erinnerte sich an das ständige Wehklagen ihrer eigenen Mutter über ihre unverheiratete Situation. So packte sie ihre Koffer, nahm ihr Kind an der Hand und verliess Italien an einem Abend um 22:00 Uhr, ohne je zurückzublicken.
In der Schweiz fand sie ihren Ehemann, ihre Seelen fanden zueinander, und innerhalb von sechs Monaten schlossen sie den Bund der Ehe.

04 Familie
Im Juli 1968 heiratete Norma Penasa in der Schweiz und nur eine Woche später begann sie bei Forster Willi zu arbeiten. Auch an Samstagen und Sonntagen arbeitete sie. Doch am Wochenende fand sie auch Zeit mit ihren Kindern spazieren zu gehen. Zusätzlich kümmerte sie sich um ein Pflegekind nebst ihren eigenen beiden Kindern. Patrizia erinnert sich daran, wie Norma die übrig gebliebenen Spitzen ihrer Arbeit nutzte, um ihre eigenen Leintücher zu verschönern. Mit den Resten der Spitze verzierte sie ihre Heimtextilien und machte sie zu etwas Besonderem.


05 Politik
Bevor sie heiratete, arbeitete Norma in der Gastronomie als Servicekraft. Das war etwas, das alle Migranten tun mussten, um in der Schweiz bleiben zu können. Dann rief sie beim Ausländerbüro an und fragte, ob sie den Job wechseln dürfe, da sie nun verheiratet war. Sie erhielt dann die Erlaubnis, ihren Job zu wechseln.

Bei Forster Willi sah sie zum ersten Mal eine Nähmaschine. Sie wusste nicht, wo die Fäden hingehörten und wie alles funktionierte. Innerhalb von nur acht Tagen lernte sie es dort.
Ihre Tochter erzählte, dass Norma bereits 30 Jahre alt war, als sie bei Forster Willi AG anfing zu arbeiten. Zu der Zeit galt das schon als "alt". Mit 30 Jahren galt eine Frau bereits als etabliert. Die Hierarchien früher in diesen Geschäften waren stark. "Ich: Boss, Du: Nix", so war die Stellung klar definiert.

Nein, während der Arbeit hörte Norma kein Radio. Sie interessierte sich nur für die Arbeit selbst und nicht dafür, was im Radio lief. Sie waren Italienerin. «Bei uns Italienern hat man zuhause sowieso schon genug gehört, da brauchte man keinen Radio dazu.»
`}
<br />
<Typography variant="text-italic" id="footnote_norma_1">
1. Motive: Motive waren einzelne gestickte Sujets, die danach weiterverarbeitet werden konnten.
</Typography>
<br />
<br />
<Typography variant="text-italic" id="footnote_norma_2">
2. Haute Couture: Haute Couture bezieht sich auf massgeschneiderte und handgefertigte Mode, die von renommierten Designern und Modehäusern erstellt wird. Sie steht für exklusive, individuelle und hochwertige Kleidung, die oft mit luxuriösen Materialien und aufwendigen Details versehen ist.
</Typography>
<br />
<br />
<Typography variant="text-italic" id="footnote_norma_3">
3. Nachsticken: Das Nachsticken bezeichnet jegliche Form von Anpassungen an bereits gestickten Stoffen. Dies kann sich auf Ausbesserungen oder Zusammennähen von einzelnen Stickereien beziehen.
</Typography>
               </span>;
