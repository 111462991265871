import { Grid, Typography } from '@mui/material';

import { text1, text2 } from './texts';

/* eslint-disable max-len */
function ProjektDesktop() {
  return (
    <Grid
      container
      marginTop={`${26 + 19 + 22}px`}
    >
      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <div style={{
          width: 420, display: 'flex', alignItems: 'center', marginLeft: 26,
        }}
        >
          <Typography variant="text-bold" sx={{ color: '#9D9D9D' }}>
            Aus dem Schatten ins Licht:
            <br />
            Erinnerungen von ehemaligen Heimarbeiterinnen
          </Typography>
        </div>
        <div style={{ flex: 1, display: 'flex', alignItems: 'flex-start' }}>
          <Typography variant="text-bold" sx={{ whiteSpace: 'break-spaces', fontSize: '18px', lineHeight: '18px' }}>
            {text1}
          </Typography>
        </div>
        <div style={{ width: 153 + 77 + 252, display: 'flex', alignItems: 'flex-end' }} />
      </div>

      <div style={{
        display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: 70,
      }}
      >
        <div style={{ width: 151 + 213 + 209, paddingBottom: '20px' }}>
          <Typography variant="text-bold" sx={{ whiteSpace: 'break-spaces', color: '#9D9D9D', lineHeight: '17px' }}>
            <div>{text2}</div>
          </Typography>
        </div>
        <div style={{ width: 116 }} />
      </div>
    </Grid>
  );
}

export default ProjektDesktop;
