import { useRef, useState } from 'react';
import 'react-slideshow-image/dist/styles.css';

import './PersonalityMobile.css';

import { Button, Grid, Typography } from '@mui/material';
import ReactPlayer from 'react-player';
import people from '../../people';
import Slider from '../../blocks/MobileSlider';
import FullscreenPictureProvider from '../../blocks/FullscreenImageProvider';

import videoFile from '../../assets/video.mp4';

function PersonalityMobile() {
  const ref = useRef();
  const [openSlider, setOpenslider] = useState(-1);
  const [playing, setPlaying] = useState(false);
  return (
    <FullscreenPictureProvider>
      <div style={{ position: 'relative' }}>
        <div style={{
          height: '56vw',
          width: '100vw',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          overflow: 'hidden',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        >
          <div style={{ width: 100 }} />
          <Button
            variant="video"
            onClick={() => {
              setPlaying(!playing);
            }}
            sx={{ fontSize: '11px', lineHeight: '11px' }}
          >
            {!playing ? 'play' : 'pause'}
          </Button>
          <div style={{ width: 100 }}>
            <Button
              variant="video"
              onClick={() => {
                // for android
                if (document.querySelector('video').requestFullscreen) {
                  document.querySelector('video').requestFullscreen({
                    navigationUI: 'hide',
                  });
                } else {
                  // for iOS
                  document.querySelector('video')?.webkitEnterFullScreen();
                }
              }}
              sx={{ justifySelf: 'flex-end' }}
            >
              fullscreen
            </Button>

          </div>
        </div>
        <div style={{ pointerEvents: 'none', zIndex: -1 }}>
          <ReactPlayer
            ref={ref}
            url={videoFile}
            playsinline
            controls={false}
            loop
            playing={playing}
            width="100vw"
            height="56vw"
            light={false}
              // eslint-disable-next-line react/jsx-no-useless-fragment
            playIcon={<></>}
          />
        </div>

      </div>
      <div style={{ marginLeft: '21px', marginTop: '10px', paddingBottom: '70px' }}>
        <Grid container>
          <Grid item xs={6}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <a href="#lina-bischofberger" style={{ fontSize: '12px', textDecoration: 'none' }}>
                <Typography
                  variant="text-regular"
                  style={{ textTransform: 'uppercase' }}
                >
                  Lina bischofberger
                </Typography>

              </a>
              <a href="#louise-jeger" style={{ fontSize: '12px', textDecoration: 'none' }}>
                <Typography
                  variant="text-regular"
                  style={{ textTransform: 'uppercase' }}
                >
                  Louise Jeger
                </Typography>

              </a>
              <a href="#hanna-botschi" style={{ fontSize: '12px', textDecoration: 'none' }}>
                <Typography
                  variant="text-regular"
                  style={{ textTransform: 'uppercase' }}
                >
                  HANNA BOTSCHI
                </Typography>

              </a>
              <a href="#rotraut-grundler" style={{ fontSize: '12px', textDecoration: 'none' }}>
                <Typography
                  variant="text-regular"
                  style={{ textTransform: 'uppercase' }}
                >
                  ROTRAUT GRUNDLER
                </Typography>

              </a>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <a href="#norma-penasa" style={{ fontSize: '12px', textDecoration: 'none' }}>
                <Typography
                  variant="text-regular"
                  style={{ textTransform: 'uppercase' }}
                >
                  NORMA PENASA
                </Typography>

              </a>
              <a href="#hildegard-koch-rusch" style={{ fontSize: '12px', textDecoration: 'none' }}>
                <Typography
                  variant="text-regular"
                  style={{ textTransform: 'uppercase' }}
                >
                  HILDEGARD KOCH-RUSH
                </Typography>

              </a>
              <a href="#agnes-ruegg-rutsche" style={{ fontSize: '12px', textDecoration: 'none' }}>
                <Typography
                  variant="text-regular"
                  style={{ textTransform: 'uppercase' }}
                >
                  AGNES RUEGG-RUTSCHE
                </Typography>

              </a>
              <a href="#angelica-pfister" style={{ fontSize: '12px', textDecoration: 'none' }}>
                <Typography
                  variant="text-regular"
                  style={{ textTransform: 'uppercase' }}
                >
                  ANGELICA PFISTER
                </Typography>

              </a>
            </div>
          </Grid>
        </Grid>
      </div>
      {people.map((p, key) => (
        <Slider
          key={p.name}
          title={p.name}
          pictures={p.mobileImages}
          mobileTitle={p.mobileTitle}
          mp3={p.mp3}
          open={openSlider === key}
          setOpen={(a) => setOpenslider(a || key)}
          overlay={openSlider >= 0 && openSlider !== key}
        />
      ))}
    </FullscreenPictureProvider>
  );
}

export default PersonalityMobile;
