import _01 from './01.webp';
import _02 from './02.webp';
import _03 from './03.webp';
import _04 from './04_Fullscreen.webp';
import _05 from './05.webp';
import _06 from './06.webp';
import _07 from './07.webp';
import _08 from './08.webp';
import _09 from './09.webp';

import hoverName from './Hover_Name_5x4.webp';
import hoverOrt from './Hover_Ort_5x4.webp';

export { hoverName, hoverOrt };
export default [
  {
    url: _01,
    title: 'LINA BEI DER ARBEIT',
    fullscreen: false,
    caption: {
      home: 'REUTE',
      canton: 'AR',
      year: '1966',
    },
  },
  {
    url: _02,
    title: 'FRÜHERES ZUHAUSE',
    fullscreen: false,
    caption: {
      home: 'REUTE',
      canton: 'AR',
      year: '1963 ',
    },
  },
  {
    url: _03,
    title: 'FRÜHERES ZUHAUSE VON WEITEM',
    fullscreen: false,
    caption: {
      home: 'REUTE',
      canton: 'AR',
      year: '2023',
    },
  },
  {
    url: _04,
    title: 'PORTRAIT I',
    fullscreen: true,
    caption: {
      home: 'REUTE',
      canton: 'AR',
      year: '2023 ',
    },
  },
  {
    url: _05,
    title: 'AUSSICHT AUS DEM ZIMMER',
    fullscreen: false,
    caption: {
      home: 'REUTE',
      canton: 'AR',
      year: '2023',
    },
  },
  {
    url: _06,
    title: 'PORTRAIT II',
    fullscreen: false,
    caption: {
      home: 'REUTE',
      canton: 'AR',
      year: '2023',
    },
  },
  {
    url: _07,
    title: 'GEMEINDEFAHNE',
    fullscreen: false,
    caption: {
      home: 'REUTE',
      canton: 'AR',
      year: '2023',
    },
  },
  {
    url: _08,
    title: 'HOCHZEITSBILD',
    fullscreen: false,
    caption: {
      home: 'REHETOBEL',
      canton: 'AR',
      year: '1948 ',
    },
  },
  {
    url: _09,
    title: 'PORTRAIT III',
    fullscreen: false,
    caption: {
      home: 'REUTE',
      canton: 'AR',
      year: '2023',
    },
  },
];
