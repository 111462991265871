import CurrentPictureProvider from '../../blocks/CurrentPictureProvider';
import useDeviceDetect from '../../utils/useDeviceDetect';
import PersonalityDesktop from './PersonalityDesktop';
import PersonalityMobile from './PersonalityMobile';

function Personality() {
  const isMobile = useDeviceDetect();

  if (isMobile === null) {
    return null;
  }
  if (isMobile) {
    return <PersonalityMobile />;
  }
  return <CurrentPictureProvider><PersonalityDesktop /></CurrentPictureProvider>;
}

export default Personality;
