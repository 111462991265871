import { Button, IconButton, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

const makeStyle = (active) => {
  if (!active) {
    return null;
  }
  return {
    textDecoration: 'underline',
  };
};

function AudioPlayer({ chapters = {} }) {
  const audioRef = useRef([]);
  const [loaded, setLoaded] = useState(false);
  const [chapterKey, setCurrentKey] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (loaded === false) {
      setLoaded(true);
    }
  });
  const handleOnClickChapter = (key) => {
    setCurrentKey(key);
  };

  useEffect(() => {
    if (loaded && !!audioRef.current[chapterKey]) {
      // audioRef.current.forEach((ref) => ref.audioEl.current.pause());
      document.querySelectorAll('audio').forEach((audio) => {
        audio.pause();
      });
      audioRef.current[chapterKey].audioEl.current.play().then(() => setIsPlaying(true));
    }
  }, [chapterKey]);

  const onPressPlay = () => {
    if (chapterKey === null) {
      setCurrentKey(0);
    } else {
      audioRef.current[chapterKey].audioEl.current.play().then(() => setIsPlaying(true));
    }
  };

  const onPressPause = () => {
    audioRef.current[chapterKey].audioEl.current.pause();
    setIsPlaying(false);
  };

  const onPressForward = () => {
    if (audioRef.current[chapterKey].audioEl.current.currentTime < 3 && chapterKey > 0) {
      audioRef.current[chapterKey - 1].audioEl.current.currentTime = 0;
      setCurrentKey(chapterKey - 1);
    } else {
      audioRef.current[chapterKey].audioEl.current.currentTime = 0;
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <Typography variant="text-regular" sx={{ fontSize: '17px', lineHeight: '17px' }}>AUDIO :</Typography>
        <IconButton onClick={onPressForward}>
          <FastRewindIcon style={{ color: 'black' }} />
        </IconButton>
        <IconButton onClick={isPlaying ? onPressPause : onPressPlay}>
          {!isPlaying ? <PlayArrowIcon style={{ color: 'black' }} /> : <PauseIcon style={{ color: 'black' }} />}
        </IconButton>
      </div>
      <div style={{
        position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'flex-start',
      }}
      >
        <Typography
          variant="text-bold"
          sx={{
            position: 'absolute', top: 0, left: 0, translate: 'calc(-100% - 20px)',
          }}
        >
          Kapitel :
        </Typography>
        {chapters.map((c, key) => (
          <Button key={c.name} variant="audio-chapter" onClick={() => handleOnClickChapter(key)}>
            <span style={makeStyle(key === chapterKey)}>{c.name.toUpperCase()}</span>
          </Button>
        ))}
      </div>
      {chapters.map((chap, key) => (
        <ReactAudioPlayer
          key={chap.url}
          src={chap.url}
          ref={(ref) => { audioRef.current[key] = ref; }}
          onEnded={() => {
            if (key < chapters.length - 1) {
              setCurrentKey(key + 1);
            }
          }}
        />
      ))}
    </div>
  );
}

export default AudioPlayer;
