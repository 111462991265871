/* eslint-disable react/jsx-indent */

import { Typography } from '@mui/material';

/* eslint-disable max-len */
export default <span>
  {`01 Heimarbeit
Agnes und ihre Familie waren bei verschiedenen Firmen angestellt, zum Beispiel Seiler in St. Gallen oder Breitenmoser im Appenzell. Obwohl sie den ganzen Tag in der Schule verbrachten, fanden sie und ihre Schwestern abends noch ein bis zwei Stunden Zeit, um tatkräftig mit anzupacken. Im Laufe der Zeit übertrug ihre Mutter ihnen auch Haushaltsaufgaben. Nach ihrer Rückkehr von der Schule um 17 Uhr begannen sie sofort zu arbeiten, unterbrochen von einer kurzen Abendmahlzeit. Oft gab es Milch und Brocken (Brotstücke). Für Agnes war dies keine mühsame Tätigkeit, sondern einfach ein Teil ihres Alltags. Auch am Samstag war die Familie am Arbeiten, sonntags gönnten sie sich eine Runde Jassen, ein beliebtes schweizerisches Kartenspiel. Sobald alle Nadeln gefädelt waren, war Feierabend für die Kinder.

Agnes’ Vater startete bereits um 5 Uhr morgens und arbeitete bis spät in die Nacht hinein. Aufgrund seiner zahlreichen zusätzlichen Ämter musste er oft abends unterwegs sein und musste daher früher aufhören. Dies kam den Schwestern zugute, denn dann konnten auch sie früher den Arbeitsalltag hinter sich lassen.

Das Sticken und die Arbeit auf dem Bauernhof waren zweifellos kräftezehrend. Zuerst war man draussen beschäftigt und musste sich dann drinnen konzentrieren, um nicht einzuschlafen. Doch weder Agnes noch ihre Geschwister hatten das Sticken je erlernt oder selbst betrieben. Ihr Vater hatte es in seiner Kindheit von seiner Mutter gelernt, doch aus Zeitgründen konnte er es Agnes und ihren Schwestern nicht beibringen. Zudem hatte ihr Vater erneut geheiratet, und ihre Stiefmutter half mehr mit, genauso wie ihre jüngeren Geschwister.

02 Stickerei
Agnes erklärte uns wie man sich die Stickmaschine vorstellen kann: Es gab insgesamt 104 Rahmen, in die der Stoff eingespannt wurde. Das waren vier Holzlatten, in die jeweils 26 Rahmen gesetzt wurden. Sobald das Muster`}
<sup>
<a href="#footnote_agnes_1">1</a>
</sup>
{` fertiggestellt war, wurden die Rahmen aus der Maschine genommen und frische Rahmen bereitgestellt, um sie wieder einzusetzen.

Um die Rahmen zu leeren, legte man die Latten auf den Tisch und entfernte den Stoff, während die fertigen Tüchlein`}
<sup>
<a href="#footnote_agnes_2">2</a>
</sup>
{' zusammengefaltet wurden. Das "Ausrähmeln'}
<sup>
<a href="#footnote_agnes_3">3</a>
</sup>
{`" und Zusammenfalten habe vor allem Agnes übernommen, nachdem ihre Mutter verstorben war. Zwischendurch habe sie auch die Nadeln neu eingefädelt und geputzt. Einfach mitgeholfen, wo es möglich war.

Wenn ein Muster fertig gestickt wurde, bereitete Agnes frischen Faden vor um "Aufzustecken". Die Kleinsten stiegen in den Wagen und zogen alles gerade, damit sich die Fäden nicht verwickelten. Dieses Zusammenspiel nannten sie "Verteilen".
Wenn es an einer bestimmten Farbe mangelte, überprüften sie sorgfältig, ob sie genug für das Muster hatten, andernfalls mussten sie neue Fäden bestellen.

Dieses Vorgehen erledigten sie im Voraus, nicht erst, wenn es dringend wurde. Manchmal erteilten sie die Bestellung telefonisch. Schon als Kinder hatten sie immer ein Telefon zu Hause. Sie waren oft damit beschäftigt Telefonate weiterzuleiten. Ihr Vater als Feuerwehrkommandant benötigte das Telefon dringend. Sonst hätten sie wahrscheinlich keines gehabt.

Jedes Muster gab Auskunft über die Anzahl der Stiche, die es enthielt. Der Sticker wurde pro 100 Stiche bezahlt. Wenn sie den Rahmen nachspannen`}
<sup>
<a href="#footnote_agnes_4">4</a>
</sup>
{` mussten, weil der Stoff breiter war als der Rahmen, wurde ein Aufschlag berechnet. Das Ziel ihres Vaters waren 3’000 Stiche pro Tag. Als sie Kinder waren und noch mithalfen, schafften sie wahrscheinlich noch mehr. Wenn das Muster besonders anspruchsvoll war, erhöhte sich die Anzahl der Stiche entsprechend und der Preis stieg ein wenig.

03 Jugendzeit
Gelegentlich unternahmen sie auch Tagesausflüge, wie beispielsweise einen Besuch auf der Ebenalp. Ferien im heutigen Sinne gab es jedoch nicht.
Stattdessen zogen sie an Samstagen mittags in den Wald, um Holz zu sammeln. Eine andere Art von Arbeit, aber immerhin waren sie draussen in der Natur, umgeben von frischer Luft.

Als Agnes 20 Jahre alt war, verliess sie ihr Elternhaus. Sie hatte die Gelegenheit, die Bäuerinnenschule zu besuchen und arbeitete anschliessend 3.5 Jahre lang als Familienhelferin.

Während ihrer Schulzeit trug sie einen Schürzenkittel, wie alle anderen Kinder auch. Zu Hause wechselten sie dann ihre Kleidung. Beim Mittagessen reichte es aus, nur den Schürzenkittel zu wechseln. Doch abends mussten sie sich komplett umziehen, in ältere Kleidung, dies nicht, weil man beim Sticken dreckig wurde, sondern damit sie die schöne Kleidung für auswärtige Besuche aufsparen konnten.
Agnes erwähnt, dass, anders als auf dem Bauernhof, sie ja nicht in den Stall gehen mussten, wo die Kleidung dreckig geworden wäre.

Agnes und ihre Schwestern hätten viel lieber im Stall gearbeitet. Jede Schwester fragte die andere: "Fädelst du heute?" Es gab nämlich zwei Bauernhöfe in der Nähe, die immer dankbar für ihre Hilfe waren.
Jede von ihnen wollte lieber auf den Hof gehen, anstatt zu fädeln. Doch mindestens eine musste immer zu Hause bleiben und dem Vater helfen.

Manchmal durfte Agnes auch einem der beiden Bauern helfen. Einmal half sie auf dem Hof aus, doch der Bauer schickte sie nach Hause, weil sie ihre Aufgabe nicht gut genug erledigt hatte.
Da dachte sie, er hätte ihr lieber genauer zeigen sollen, wie es richtig gemacht wird.


04 Familie
Die Stickmaschine`}
<sup>
<a href="#footnote_agnes_5">5</a>
</sup>
{` wurde von Agnes' Grossvater gekauft, dem Vater ihres Vaters. Das war im Jahr 1913, und die Maschine kostete damals 8'000 Schweizer Franken. Im Jahr 1919 verstarb ihr Grossvater und ihre Grossmutter führte das Stickgeschäft weiter. Dadurch konnte sie die Kinder grossziehen und ihren Lebensunterhalt bestreiten. Zu dieser Zeit gab es noch keine Witwen- oder Waisenrente, daher wurden Kinder manchmal in ein Heim gebracht.

Als Agnes' Vater noch ein Junge war, lernte er von seiner Mutter das Sticken. Nebenbei bewirtschafteten sie auch 2.5 Hektar Land und verdienten sich etwas dazu, vor allem durch den Verkauf von Milch von 2-3 Kühen. Es war eine Kombination aus dem Stickgeschäft und der Landwirtschaft, die ihnen ermöglichte über die Runden zu kommen.
`}
<br />
<Typography variant="text-italic" id="footnote_agnes_1">
1. Muster: Ein vorgesticktes Motiv, das den Sticker:innen einen Anhaltspunkt bietet, wie das vollendete Produkt aussehen soll.
</Typography>
<br />
<br />
<Typography variant="text-italic" id="footnote_agnes_2">
2. Tüchlein: Ein kleines Stück Stoff, das traditionell in der Schweiz bestickt wird. Die Stickerei auf dem Tüchli ist oft sehr filigran und detailreich und zeigt verschiedene Muster und Motive.
</Typography>
<br />
<br />
<Typography variant="text-italic" id="footnote_agnes_3">
3. Ausrähmeln: Nachdem, der Stoff fertig bestickt ist, wird der Stoff aus den Rahmen entfernt, welche für die Maschine benötigt waren.
</Typography>
<br />
<br />
<Typography variant="text-italic" id="footnote_agnes_4">
4. nachspannen: Da die Stickmotive unterschiedliche Grössen hatten, waren diese nicht immer in derselben Grösse wie der Rahmen, welcher in die Stickmaschine eingeführt wurde. Wenn ein Motiv über den Rahmen hinausging, musste man die Tüchli rausnehmen und wieder neu positionieren, damit weitergestickt werden konnte.
</Typography>
<br />
<br />
<Typography variant="text-italic" id="footnote_agnes_5">
5. Stickmaschine: Gemeint ist hier die Handstickmaschine. Im Jahr 1829 erfand Josua Heilmann das Konzept der Plattstich-Handstickmaschine. Obwohl die Technologie bis zum Ende der Handstickerei nahezu unverändert blieb, wurde sie erst um 1850 vollständig entwickelt und in großer Menge produziert.
</Typography>
{' '}

               </span>;
