import React, { useState, useEffect } from 'react';

function CustomCursor({ text, disabled }) {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const setFromEvent = (e) => {
      setPosition({ x: e.clientX, y: e.clientY });
    };
    window.addEventListener('mousemove', setFromEvent);

    return () => {
      window.removeEventListener('mousemove', setFromEvent);
    };
  }, []);

  if (disabled) {
    return null;
  }

  return (
    <div
      style={{
        position: 'fixed',
        top: `${position.y}px`,
        left: `${position.x}px`,
        pointerEvents: 'none',
        transform: 'translate(5%, 10%)',
        zIndex: 5,
        cursor: 'none',
      }}
      className="cursor"
    >
      <svg>
        <text x="0" y="15" fill="white" fontFamily="EduDiatype-Mono-Regular">{text}</text>
      </svg>
    </div>
  );
}

export default CustomCursor;
