import useDeviceDetect from '../../utils/useDeviceDetect';
import HeaderMobile from './HeaderMobile';
import HeaderDesktop from './HeaderDesktop';

function Header() {
  const isMobile = useDeviceDetect();
  if (isMobile === null) {
    return null;
  }
  if (isMobile) {
    return <HeaderMobile />;
  }

  return <HeaderDesktop />;
}

export default Header;
