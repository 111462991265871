/* eslint-disable no-param-reassign */
import { Button, Grid, Typography } from '@mui/material';
import { Slide } from 'react-slideshow-image';
import {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import slug from 'slug';
import LeftArrow from '../../../assets/icons/LeftArrow';
import RightArrow from '../../../assets/icons/RightArrow';
import Image from './Image';
import PersonalityOverlay from './PersonalityOverlay';
import AudioPlayer from './AudioPlayer';
import { CurrentPictureContext } from '../../../blocks/CurrentPictureProvider';

const buttonBaseStyle = {
  height: '100%',
  width: '20vw',
  color: 'white',
  background: 'none',
  border: 'none',
  fontSize: 25,
  fontWeight: 'bold',
  display: 'flex',
  alignItems: 'center',
  padding: 20,
  outline: 'none',
};

const leftButtonStyle = {
  ...buttonBaseStyle,
  justifyContent: 'flex-start',
  // backgroundColor: 'rgba(0, 255, 0, .2)',
};

const rightButtonStyle = {
  ...buttonBaseStyle,
  justifyContent: 'flex-end',
  // backgroundColor: 'rgba(255, 0, 0, .2)',
};

function PersonalityDesktopPage({
  name, pictures, desktopTitle, transcription, description, mp3Chapters, isCurrent,
}) {
  const [isColliding, setIsColliding] = useState(false);
  const [headerIsColliding, setHeaderIsColliding] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [currentPic, setCurrentPic] = useState(0);

  const { setTotalPictures, setPicture, picture: pict } = useContext(CurrentPictureContext);

  useEffect(() => {
    if (isCurrent) {
      setTotalPictures(pictures.length);
      if ((currentPic + 1) !== pict) {
        setPicture(currentPic + 1);
      }
    }
  }, [isCurrent, currentPic]);

  const slugName = useMemo(() => slug(name), [name]);

  const handleOnChange = useCallback((from, to) => {
    const picture = pictures[to];
    setPicture(to + 1);
    if (picture.fullscreen && !isColliding) {
      setIsColliding(true);
    } else if (isColliding) {
      setIsColliding(false);
    }

    const pictureRect = document.getElementById(`picture-${slugName}-${to}`).getBoundingClientRect();
    const headerRect = document.getElementById('header').getBoundingClientRect();

    if (picture.fullscreen && headerRect.bottom > pictureRect.top
      && headerRect.top < pictureRect.bottom
      && headerRect.right > pictureRect.left
      && headerRect.left < pictureRect.right
    ) {
      setHeaderIsColliding(true);
    } else {
      setHeaderIsColliding(false);
    }
    setCurrentPic(to);
  }, [pictures, name, isColliding, isCurrent]);

  useEffect(() => {
    const els = document.querySelectorAll(`#${slugName} .adapt-color`);
    if (isColliding) {
      els.forEach((el) => { el.style.color = 'white'; });
    } else {
      els.forEach((el) => { el.style.color = 'black'; });
    }
  }, [isColliding]);

  useEffect(() => {
    const els = document.querySelectorAll('#header .adapt-color');
    if (headerIsColliding) {
      els.forEach((el) => { el.style.color = 'white'; });
    } else {
      els.forEach((el) => { el.style.color = 'black'; });
    }
  }, [headerIsColliding]);

  return (
    <Grid
      id={slugName}
      className="personality-slide"
      container
      sx={{
        height: '100vh', width: '100%', position: 'relative',
      }}
    >
      <Grid item sx={{ height: '100%', width: '100%' }}>
        <Slide
          canSwipe={false}
          transitionDuration={0}
          onChange={handleOnChange}
          autoplay={false}
          prevArrow={(
            <button type="button" style={leftButtonStyle} className="prev-arrow-button">
              <LeftArrow style={{ fontSize: 21 }} className="adapt-color" />
            </button>
        )}
          nextArrow={(
            <button type="button" style={rightButtonStyle} className="next-arrow-button">
              <RightArrow style={{ fontSize: 21 }} className="adapt-color" />
            </button>
        )}
        >
          {pictures.map((pic, key) => (
            <div
              key={pic.url || pic}
              id={`picture-${slugName}-${key}`}
              style={{
                height: '100vh',
                width: '100vw',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
              }}
            >
              <Image url={pic.url || pic} fullscreen={pic.fullscreen} current={currentPic} total={pictures.length} />

              {!showOverlay && (
                <div style={{
                  position: 'absolute', bottom: 26, width: '100%', display: 'flex',
                }}
                >
                  <div style={{ width: 212 }} />
                  <div style={{
                    flexGrow: 1, display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between',
                  }}
                  >
                    <Typography variant="text-regular" className="adapt-color">{pic?.title?.toUpperCase()}</Typography>
                    <Typography variant="text-regular" className="adapt-color">
                      {pic.caption.home?.toUpperCase()}
                      &nbsp;
                      {pic.caption.canton}
                    </Typography>
                  </div>
                  <div style={{ width: 153 }} />
                  <div style={{ width: 303, display: 'flex', alignItems: 'flex-start' }}>
                    <Typography variant="text-regular" className="adapt-color">{pic.caption.year}</Typography>
                  </div>
                </div>
              )}

            </div>
          ))}
        </Slide>
      </Grid>
      {showOverlay && (
      <PersonalityOverlay
        top={245}
        name={name}
        desktopTitle={desktopTitle}
        transcription={transcription}
        description={description}
        onClose={() => {
          document.querySelector('body').classList.remove('stop-scrolling');
          setShowOverlay(false);
        }}
      />
      )}
      <Grid
        item
        sx={{
          height: '100%',
          width: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          paddingLeft: '26px',
          paddingRight: '26px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div style={{ height: '245px', width: '100%' }} />
        <div style={{
          display: 'flex', width: '100%', flex: 1, alignItems: 'flex-start', position: 'relative',
        }}
        >
          <div style={{
            width: 420, display: 'flex', justifyContent: 'space-between', zIndex: 11,
          }}
          >
            <Button
              variant="personality-info"
              className="adapt-color"
              onClick={() => {
                document.querySelector('body').classList.add('stop-scrolling');
                setShowOverlay(true);
              }}
            >
              <span style={{ textDecoration: 'underline' }}>INFO</span>
            </Button>
            <Typography variant="text-bold" className="adapt-color">{name}</Typography>
          </div>
          {mp3Chapters?.length && (
            <div style={{
              position: 'absolute', top: 0, left: 'calc(100% - 303px)', zIndex: 1002,
            }}
            >
              <AudioPlayer chapters={mp3Chapters} />
            </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
}

export default PersonalityDesktopPage;
