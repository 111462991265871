import _01 from './01.webp';
import _02 from './02.webp';
import _03 from './03_Fullscreen.webp';
import _04 from './04.webp';
import _05 from './05.webp';
import _06 from './06.webp';
import _07 from './07.webp';
import _08 from './08.webp';
import _09 from './09.webp';
import _10 from './10.webp';

import hoverName from './Hover_Name_5x4.webp';
import hoverOrt from './Hover_Ort_5x4.webp';

export { hoverName, hoverOrt };
export default [
  {
    url: _01,
    title: 'NÄHSTUBE',
    fullscreen: false,
    caption: {
      home: 'ST.GALLEN',
      canton: 'SG',
      year: '2023',
    },
  },
  {
    url: _02,
    title: 'SELBST BESTICKTER BETTBEZUG',
    fullscreen: false,
    caption: {
      home: 'ST.GALLEN',
      canton: 'SG',
      year: '2023',
    },
  },
  {
    url: _03,
    title: 'MIT STICKEREIEN VERZIERTES BADEZIMMER',
    fullscreen: true,
    caption: {
      home: 'ST.GALLEN',
      canton: 'SG',
      year: '2023',
    },
  },
  {
    url: _04,
    title: 'NORMAS GELIEBTE NÄHMASCHINE',
    fullscreen: false,
    caption: {
      home: 'ST.GALLEN',
      canton: 'SG',
      year: '2023',
    },
  },
  {
    url: _05,
    title: 'BEI DER ARBEIT',
    fullscreen: false,
    caption: {
      home: 'ST.GALLEN',
      canton: 'SG',
      year: '1980',
    },
  },
  {
    url: _06,
    title: 'PORTRAIT I',
    fullscreen: false,
    caption: {
      home: 'ST.GALLEN',
      canton: 'SG',
      year: '2023',
    },
  },
  {
    url: _07,
    title: 'MIT IHREM MANN UNTER FREUNDEN',
    fullscreen: false,
    caption: {
      home: 'ST.GALLEN',
      canton: 'SG',
      year: '1963',
    },
  },
  {
    url: _08,
    title: 'BESUCH BEI DER ARBEIT',
    fullscreen: false,
    caption: {
      home: 'ST.GALLEN',
      canton: 'SG',
      year: '1983',
    },
  },
  {
    url: _09,
    title: 'AUS NORMAS FUNDGRUBE',
    fullscreen: false,
    caption: {
      home: 'ST.GALLEN',
      canton: 'SG',
      year: '2023',
    },
  },
  {
    url: _10,
    title: 'PORTRAIT II',
    fullscreen: false,
    caption: {
      home: 'ST.GALLEN',
      canton: 'SG',
      year: '2023',
    },
  },
];
