/* eslint-disable react/jsx-indent */

import { Typography } from '@mui/material';

/* eslint-disable max-len */
export default <span>
  {`01 Heimarbeit
Durch einen Zufall wurde Angelica Pfister in die Heimarbeit eingeführt. Eine Nachbarin, die selbst von zu Hause aus arbeitete, brachte sie mit Herrn Biedermann in Kontakt. Angelica zeigte Interesse und kurze Zeit später erhielt sie einen Anruf, ob sie immer noch interessiert sei. So begann sie von einem Tag auf den anderen bei Bischoff Textil zu arbeiten. Abfüllen`}
<sup>
<a href="#footnote_angelica_1">1</a>
</sup>
. Anschreiben
<sup>
<a href="#footnote_angelica_2">2</a>
</sup>
. Etikettieren
<sup>
<a href="#footnote_angelica_3">3</a>
</sup>
{`. Die Textilien waren damals sehr gefragt.

Manchmal bekam sie ganze Kisten voller Blümchen, in verschiedenen Farben wie Rot, Blau, Gelb und Violett. Angelica sortiert die Blumen nach Farben in kleine Beutel und liess nebenbei den Fernseher laufen. Sie arbeitete, wenn die Kinder schliefen oder oft auch nachts, je nach Auftragslage. In der Nacht merkte sie manchmal die Auswirkungen auf ihre Augen, aber sie liess sich nicht davon abhalten.
Sie hatte sich das alles selbst beigebracht.

Bei Forster Rohner tüftelte Angelica selbst daran, wie sie die Aufträge am besten erledigen konnte. Jeder machte es ein wenig anders, aber sie fand zu Hause selbst heraus, wie sie die Arbeit am schnellsten erledigen konnte. Dafür brauchte sie ein eigenes Zimmer, das sie selbst einrichtete. Es gab viele Kisten, einerseits die, die noch bearbeitet werden mussten, und andererseits die, die bereits erledigte waren. 
Sie wollte nicht, dass die Kinder und die Katzen an die Kisten kommen. Bei den Katzen wegen der Haare, bei den Kindern war es die Sorge um die weissen Stoffe. Ihr Schreibtisch stand am Fenster, damit sie Tageslicht hatte. Abends hatte sie ein Licht installiert, das von der Seite kam, sodass sie die Fehler gut erkennen konnte. Tagsüber erledigte sie die schwarzen Stoffe und abends die helleren. Das war angenehmer für ihre Augen, besonders wenn sie 14-15 Stunden arbeitete. Manchmal klingelte das Telefon: 
"Wir haben etwas. Wie viel möchtest du haben?" - "Wie viel habt ihr? Bringt so viel vorbei."

Es gab auch manchmal Wochen, in denen keine neuen Aufträge hereinkamen. Aber Angelica mochte ihre Arbeit gerne, also überstand sie die Pausen. 
Wenn etwas nicht stimmte, rief sie bei der Firma an und ging vorbei, um sich die zu erledigenden Sachen anzuschauen.

Der Lohn veränderte sich nicht. Mit der Zeit arbeitete sie einfach effizienter, sodass sie für denselben Lohn weniger Zeit aufbringen musste. 
Im Laufe der Zeit entwickelte sie neue Techniken und nahm die Sachen anders in Angriff, sodass sie schneller fertig wurde. Die Stückpreise blieben jedoch immer gleich. Zeitweise hatte sie einen guten Stundenlohn und konnte sich nicht beklagen. Zu Beginn musste man geduldig sein, man war langsam, bis man immer schneller wurde. Irgendwann hätte sie die Arbeit auch blind erledigen können, sie funktionierte einfach.

Angelica liess nebenbei den Fernseher laufen, während sie arbeitete. Sie konnte nicht wirklich zuschauen, aber es ging darum, nicht so allein zu sein, wenn sie abends arbeitete. Es war beruhigend, etwas im Hintergrund laufen zu haben. Allerdings tat sie das nicht immer. Nur wenn viele Aufgaben anstanden, wollte sie alle annehmen, da sie nicht wusste, wie lange es dauern würde, bis neue Aufträge eintrafen. So arbeitete sie Tag für Tag, ohne einen typischen Wochenalltag zu haben. Am Wochenende arbeitete sie zwar etwas weniger, aber nicht viel. Der Druck lastete auf ihr.

Bei Forster Rohner war es anders. Sie fragten sie, wie viel sie auf den nächsten Morgen erledigen könne. Sie machten Vorschläge, wie viele Rollen oder Kartons noch zu erledigen waren. Angelica konnte das für sich organisieren, und der Rest war nicht so dringend. Die Kommunikation dort war angenehmer und half ihr einzuschätzen, was sie wann brauchten. Die Arbeit hatte bereits körperliche Spuren hinterlassen, insbesondere am Rücken und den Augen. Angelica war die letzte Heimarbeiterin, die noch von zu Hause aus arbeitete.

02 Stickerei
Zu Beginn war Angelica nicht besonders begeistert von der Stickerei, doch im Laufe der Zeit entwickelte sie eine Faszination dafür. Sie wollte alles sehen und berühren. Anfangs bekam sie Anweisungen zum Einfädeln und zur Kontrolle. Später lernte sie den Umgang mit einer Maschine, um die Fransen zu schneiden. 

Am meisten mochte sie jedoch die Maschine, mit der sie die Stickereien aufrollte`}
<sup>
<a href="#footnote_angelica_4">4</a>
</sup>
{`. Sie konnte sie entweder einfach aufwickeln oder auf Karton spulen. Die Stickereien rollte sie auf 13.80m oder 10.20m auf. 
Zuerst breitete sie die Stoffe aus, betrachtete sie sorgfältig und kontrollierte sie auf Fehler. Anschliessend mass sie sie aus und begann mit dem Aufwickeln. Diese Tätigkeit wurde zu ihrer Hauptaufgabe, die sie über Jahre hinweg ausführte. Es gab unterschiedliche Preise, je nach Länge und Menge. Zum Beispiel bekam sie für ein Stück 20 Rappen oder 30 Rappen.

Die Stickerei wurde zu einem Teil von Angelica. Ihre Finger kannten die Bewegungen auswendig. Sie wurde eins mit dem Rhythmus der Maschine und der Faszination, die von jedem fertigen Werk ausging. Es war ein stolzer Moment, wenn sie die aufgerollten Stickereien betrachtete und wusste, dass sie mit ihrer eigenen Kunstfertigkeit dazu beigetragen hatte.

03 Familie
Angelica hatte zunächst nur ein Kind und wusste, dass sie noch ein weiteres Kind haben wollte. Doch sie wollte ihre Kinder nicht in die Kinderbetreuung schicken und entschied sich dafür, zuhause zu bleiben und Heimarbeit zu machen. 
Es war eine bewusste Entscheidung, entweder nach Heimarbeit zu suchen oder die Kinder abzugeben, wenn sie auswärts hätte arbeiten wollen. Manchmal spielte ihre Tochter mit den Stickereien. Es war schön zu sehen, wie sie mit ihrer Neugier und Kreativität in die Welt der Stoffe eintauchte.

Die Arbeit hatte definitiv Einfluss auf Angelicas Privatleben, aber sie arrangierte sich damit. Zusätzlich zu ihrer Arbeit hatte sie auch die alleinige Verantwortung für den Haushalt und die Erziehung. 

Bei ihrem Mann standen seine Hobbys an erster Stelle. Angelica trug eine grosse Verantwortung, war aber froh, dass sie zuhause arbeiten konnte, um all die Verpflichtungen unter einen Hut zu bringen. Sobald die Kinder in der Schule waren, setzte sie sich drei Stunden lang an die Maschine. Dann bereitete sie das Mittagessen vor und ass mit den Kindern zu Mittag. Nachdem die Kinder wieder in der Schule waren, kümmerte sie sich um die Küche und kehrte dann in ihr Zimmer zurück, um weiterzuarbeiten. Es entstand ein guter Ablauf. Früher gab es noch keine Mobiltelefone, nur ein Festnetztelefon. Angelica zog den Stecker, um nicht gestört zu werden. Sie schaltete sogar die Türklingel aus, um nicht abgelenkt zu werden und ungestört 3-4 Stunden durcharbeiten zu können.

Es waren sehr lange Tage. Manchmal ging sie erst um 2 oder halb 3 Uhr morgens ins Bett und stand um 6 oder halb 7 Uhr wieder auf. Als die Kinder älter wurden, standen sie von alleine am Morgen auf. 
Dann konnte sie manchmal erst um 7 oder halb 8 Uhr aufstehen. Sie erinnert sich an lange Tage…

In dieser Zeit hatte Angelica eher weniger Momente für sich selbst.

Das Geld, welches sie verdiente, gehörte ihr. Sie wollte immer arbeiten. Auch heute noch arbeitet sie. Arbeiten, arbeiten, arbeiten - das war schon immer ihre Einstellung. Sie hat sich selbst und auch ihre Kinder finanziert. Kleidung und all das, das kam von ihr. Darauf war sie stolz. Sie wollte ihren Beitrag leisten und ihr eigenes Geld verdienen. Sie wollte nicht um Erlaubnis fragen müssen. Sie wollte ihr eigenes Geld. Sie erinnert sich noch genau daran, was sie mit ihrem ersten eigenen Lohn gekauft hat. Es waren Kleider für ihre Tochter. Rote Hosen und rosarote Hosen aus Manchesterstoff. Sie hat sie im Migros gekauft. Das weiss sie noch ganz genau.

05 Politik
Angelica hatte eine AHV- und IV-Versicherung. Alles wurde ordnungsgemäss abgerechnet. Die Anzahl der Stücke konnte leicht überprüft werden, wenn sie die fertigen Aufträge zurückgab. Ihr Lohn wurde entsprechend berechnet. Wenn sie viele Ausschusswaren hatte, gab sie diese auch separat zurück. Alles wurde sauber abgerechnet.

Sie nahm sich keine Urlaubstage. Manchmal kam eine enorme Menge an Arbeit, und dann gab es wieder zwei Wochen lang nichts. Sie wollte nicht zu viele zusätzliche Urlaubstage nehmen, da es keine Garantie gab, wann der nächste Auftrag kommen würde. Wenn sie eine Woche lang keine Arbeit hatte und anrief, konnten sie ihr auch nicht sagen, wann der nächste Auftrag kommen würde. Die Firma konnte ihr nicht garantieren, wie viel Arbeit es geben würde und wann sie kommen würde.

Angelica hatte einen Arbeitsvertrag, der besagte, dass sowohl sie als auch die Firma jederzeit kündigen konnten. Sozusagen fristlos, da man nie genau wusste, wie viel Arbeit es geben würde.`}
  <br />
{' '}
<br />
<Typography variant="text-italic" id="footnote_angelica_1">
1. Abfüllen: Das Abzählen von den Motiven (meist auf ein Dutzend) und das Abpacken in die vorhergesehenen Plastiksäcke.
</Typography>
<br />
<br />
<Typography variant="text-italic" id="footnote_angelica_2">
2. Anschreiben: Die Anzahl und Namen der jeweiligen Motive in den Säcken.
</Typography>
<br />
<br />
<Typography variant="text-italic" id="footnote_angelica_3">
3. Etikettieren: Nachdem die Motive eingepackt wurden, wurden die Säcke verschlossen und das Logo der jeweiligen Firma am Rand angeheftet.
</Typography>
<br />
<br />
<Typography variant="text-italic" id="footnote_angelica_4">
4. Stickereien aufrollte: Die Stickereistoffe wurden in Rollen versandt und aufbewahrt. Dies diente zur einfacheren Handhabung und zum Platz sparen.
</Typography>
               </span>;
