import useDeviceDetect from '../../utils/useDeviceDetect';
import CurrentPictureProvider from '../../blocks/CurrentPictureProvider';
import ProjektMobile from './ProjektMobile';
import ProjektDesktop from './ProjektDesktop';

function Projekt() {
  const isMobile = useDeviceDetect();
  if (isMobile === null) {
    return null;
  }
  if (isMobile) {
    return <ProjektMobile />;
  }
  return <CurrentPictureProvider><ProjektDesktop /></CurrentPictureProvider>;
}

export default Projekt;
