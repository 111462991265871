import { useContext, useRef, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { Slide } from 'react-slideshow-image';
import slug from 'slug';
import ReactAudioPlayer from 'react-audio-player';

import MobileP from './MobileP';
import { FullscreenPictureContext } from './FullscreenImageProvider';
import PlayIcon from '../assets/icons/PlayIcon';
import CloseIcon from '../assets/icons/CloseIcon';
import PauseIcon from '../assets/icons/PauseIcon';
import LeftArrow from '../assets/icons/LeftArrow';
import RightArrow from '../assets/icons/RightArrow';

const buttonBaseStyle = {
  height: '100%',
  width: '20vw',
  color: 'white',
  background: 'none',
  border: 'none',
  fontSize: 25,
  fontWeight: 'bold',
  display: 'flex',
  alignItems: 'center',
  padding: 20,
  outline: 'none',
};

const leftButtonStyle = {
  ...buttonBaseStyle,
  justifyContent: 'flex-start',
  // backgroundColor: 'rgba(0, 255, 0, .2)',
};

const rightButtonStyle = {
  ...buttonBaseStyle,
  justifyContent: 'flex-end',
  // backgroundColor: 'rgba(255, 0, 0, .2)',
};

function Slider({
  title, pictures, mobileTitle, mp3, open, setOpen, overlay,
}) {
  const audioRef = useRef();
  const [isPlaying, setIsPlaying] = useState(false);

  const showPicture = useContext(FullscreenPictureContext);

  const onClickPlay = () => {
    if (audioRef.current.audioEl.current.paused) {
      audioRef.current.audioEl.current.play().then(() => setIsPlaying(true));
    } else {
      audioRef.current.audioEl.current.pause();
      setIsPlaying(false);
    }
  };

  return (
    <Grid container id={slug(title)}>
      <Grid item xs={12}>
        <Slide
          transitionDuration={0}
          autoplay={false}
          prevArrow={<button type="button" style={leftButtonStyle}><LeftArrow /></button>}
          nextArrow={<button type="button" style={rightButtonStyle}><RightArrow /></button>}
        >
          {pictures.map((pic) => {
            const url = pic.url || pic;
            return (
              <div key={url} className="each-slide-effect" onClick={() => showPicture(url)}>
                <div style={{
                  backgroundImage: `url(${url})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                }}
                >
                  {overlay && (
                    <div style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      backgroundColor: 'white',
                      width: '100%',
                      height: '100%',
                      opacity: 0.1,
                    }}
                    />
                  )}

                </div>
              </div>
            );
          })}
        </Slide>
      </Grid>
      <Grid item xs={12} style={{ display: 'flex' }} paddingLeft={2} paddingRight={2}>
        <MobileP style={{ flex: 1 }}>
          {title}
        </MobileP>
        <MobileP style={{
          textDecoration: open ? 'none' : 'underline',
          cursor: 'pointer',
          fontFamily: 'EduDiatype-Mono-Regular',
          fontWeight: 'normal',
        }}
        >
          {!open && <a href={`#${slug(title)}`} onClick={() => setOpen()}>INFO</a>}
          {open && (
          <span
            onClick={() => {
              setOpen(-1);
              audioRef.current.audioEl.current.pause();
            }}
          >
            <CloseIcon />
          </span>
          )}
        </MobileP>
      </Grid>
      {open
        && (
        <Grid item xs={12} paddingLeft={2} paddingRight={2} style={{ marginTop: '-42px' }}>
          <MobileP style={{
            whiteSpace: 'pre',
            textAlign: 'center',
            textTransform: 'uppercase',
            fontFamily: 'EduDiatype-Mono-Regular',
            fontWeight: 'normal',
          }}
          >
            {mobileTitle}
          </MobileP>
          <Box style={{ display: 'flex', justifyContent: 'center' }} padding={5}>
            <button
              onClick={onClickPlay}
              type="button"
              style={{
                background: 'none',
                border: 'none',
                color: 'white',
                fontFamily: 'EduDiatype-Mono-Regular',
                fontSize: '12px',
                fontWeight: 'normal',
                cursor: 'pointer',
              }}
            >
              AUDIO:
              {' '}
              {!isPlaying ? <PlayIcon /> : <PauseIcon />}
            </button>
          </Box>
        </Grid>
        )}
      <ReactAudioPlayer src={mp3} ref={audioRef} />
    </Grid>
  );
}

export default Slider;
