import _01 from './01.webp';
import _02 from './02.webp';
import _03 from './03.webp';
import _04 from './04.webp';
import _05 from './05.webp';
import _06 from './06.webp';
import _07 from './07_Fullscreen.webp';
import _08 from './08.webp';
import _09 from './09.webp';

import hoverName from './Hover_Name_5x4.webp';
import hoverOrt from './Hover_Ort_5x4.webp';

export { hoverName, hoverOrt };
export default [
  {
    url: _01,
    title: 'PORTRAIT I',
    fullscreen: false,
    caption: {
      home: 'HERISAU',
      canton: 'AR',
      year: '2023',
    },
  },
  {
    url: _02,
    title: 'FIRMENPAPIER',
    fullscreen: false,
    caption: {
      home: 'HERISAU',
      canton: 'AR',
      year: '2023',
    },
  },
  {
    url: _03,
    title: 'KLASSENFOTO – 3. REIHE, 2. VON LINKS',
    fullscreen: false,
    caption: {
      home: 'HERISAU',
      canton: 'AR',
      year: '1945',
    },
  },
  {
    url: _04,
    title: 'PORTRAIT II',
    fullscreen: false,
    caption: {
      home: 'HERISAU',
      canton: 'AR',
      year: '2023',
    },
  },
  {
    url: _05,
    title: 'IM LAGER',
    fullscreen: false,
    caption: {
      home: 'HERISAU',
      canton: 'AR',
      year: '2023',
    },
  },
  {
    url: _06,
    title: 'ÜBERBLEIBSEL DER MASCHINEN',
    fullscreen: false,
    caption: {
      home: 'HERISAU',
      canton: 'AR',
      year: '2023',
    },
  },
  {
    url: _07,
    title: 'PORTRAIT III',
    fullscreen: true,
    caption: {
      home: 'HERISAU',
      canton: 'AR',
      year: '2023',
    },
  },
  {
    url: _08,
    title: 'LESEZIMMER',
    fullscreen: false,
    caption: {
      home: 'HERISAU',
      canton: 'AR',
      year: '2023',
    },
  },
  {
    url: _09,
    title: 'STICKMASCHINE',
    fullscreen: false,
    caption: {
      home: 'HERISAU',
      canton: 'AR',
      year: '1955',
    },
  },
];
