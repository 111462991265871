import _01 from './01.webp';
import _02 from './02.webp';
import _03 from './03_Fullscreen.webp';
import _04 from './04.webp';
import _05 from './05.webp';
import _06 from './06.webp';
import _07 from './07.webp';
import _08 from './08.webp';

import hoverName from './Hover_Name_5x4.webp';
import hoverOrt from './Hover_Ort_5x4.webp';

export { hoverName, hoverOrt };
export default [
  {
    url: _01,
    title: 'PORTRAIT I',
    fullscreen: false,
    caption: {
      home: 'FLAWIL',
      canton: 'SG',
      year: '2023',
    },
  },
  {
    url: _02,
    title: 'MIT MUTTER UND VATER',
    fullscreen: false,
    caption: {
      home: 'DEGERSHEIM',
      canton: 'SG',
      year: '1947',
    },
  },
  {
    url: _03,
    title: 'AGNES UND IHRE SCHWESTER',
    fullscreen: true,
    caption: {
      home: 'DEGERSHEIM',
      canton: 'SG',
      year: '1948',
    },
  },
  {
    url: _04,
    title: 'VOM VATER BESTICKTES TÜCHLI',
    fullscreen: false,
    caption: {
      home: 'FLAWIL',
      canton: 'SG',
      year: '2023',
    },
  },
  {
    url: _05,
    title: 'HANDMASCHIENENSTICKEREI VON DAMALS',
    fullscreen: false,
    caption: {
      home: 'FLAWIL',
      canton: 'SG',
      year: '2023',
    },
  },
  {
    url: _06,
    title: 'PORTRAIT II',
    fullscreen: false,
    caption: {
      home: 'FLAWIL',
      canton: 'SG',
      year: '2023',
    },
  },
  {
    url: _07,
    title: 'AGNES ALS KIND',
    fullscreen: false,
    caption: {
      home: 'DEGERSHEIM',
      canton: 'SG',
      year: 'CA.1948',
    },
  },
  {
    url: _08,
    title: 'PORTRAIT III',
    fullscreen: false,
    caption: {
      home: 'FLAWIL',
      canton: 'SG',
      year: '2023',
    },
  },
];
