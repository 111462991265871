/* eslint-disable no-param-reassign */
import { Button, Grid } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const makeStyle = (active) => {
  if (!active) {
    return null;
  }
  return {
    textDecoration: 'underline',
  };
};

const defaultProps = {
  variant: 'header',
  LinkComponent: Link,
  disableRipple: true,
  disableTouchRipple: true,
};

function HeaderDesktop() {
  const { pathname } = useLocation();

  const headerRef = useRef(null);

  // Initialize state to track whether the element is colliding with the header
  const [isColliding, setIsColliding] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const header = headerRef.current;
      const elements = document.getElementsByClassName('picture-fullscreen');

      if (header && elements) {
        const headerRect = header.getBoundingClientRect();

        let collisionDetected = false;
        for (let i = 0; i < elements.length; i += 1) {
          const element = elements[i];
          const elementRect = element.getBoundingClientRect();

          if (headerRect.bottom > elementRect.top
            && headerRect.top < elementRect.bottom
            && headerRect.right > elementRect.left
            && headerRect.left < elementRect.right
          ) {
            collisionDetected = true;
            break;
          }
        }
        setIsColliding(collisionDetected);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const els = document.querySelectorAll('#header .adapt-color');
    if (isColliding) {
      els.forEach((el) => { el.style.color = 'white'; });
    } else {
      els.forEach((el) => { el.style.color = 'black'; });
    }
  }, [isColliding]);

  return (
    <Grid
      ref={headerRef}
      id="header"
      container
      sx={{
        position: 'fixed', top: 26, left: 26, width: 'calc(100% - 52px)', zIndex: 1000,
      }}
    >
      <Grid item sx={{ display: 'flex', width: '100%' }}>
        <div style={{ width: 420, display: 'flex', alignItems: 'flex-end' }}>
          <Button {...defaultProps} to="/" variant="logo-header" className="adapt-color">
            VERGISSMEIN-NICHT
          </Button>
        </div>
        <div style={{ flexGrow: 1, display: 'flex', alignItems: 'flex-end' }}>
          <Button {...defaultProps} to="/index" className="adapt-color">
            <span style={makeStyle(pathname === '/index')}>INDEX</span>
          </Button>
        </div>
        <div style={{ width: 153, display: 'flex', alignItems: 'flex-end' }}>
          <Button {...defaultProps} to="/kontakt" className="adapt-color">
            <span style={makeStyle(pathname === '/kontakt')}>KONTAKT</span>
          </Button>
        </div>
        <div style={{ width: 77 + 252 - 26, display: 'flex', alignItems: 'flex-end' }}>
          <Button {...defaultProps} to="/projekt" className="adapt-color">
            <span style={makeStyle(pathname === '/projekt')}>PROJEKT</span>
          </Button>
        </div>
      </Grid>
    </Grid>
  );
}

export default HeaderDesktop;
