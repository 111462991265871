import mp3 from './Lina_mobile.mp3';
import lina00mp3 from './00_Prolog_Lina.mp3';
import lina01mp3 from './01_Heimarbeit.mp3';
import lina02mp3 from './02_Stickerei.mp3';
import lina04mp3 from './04_Familie.mp3';
import lina05mp3 from './05_Politik.mp3';

export default {
  mp3,
  mp3Chapters: [
    { name: '00 PROLOG', url: lina00mp3 },
    { name: '01 HEIMARBEIT', url: lina01mp3 },
    { name: '02 STICKEREI', url: lina02mp3 },
    { name: '04 FAMILIE', url: lina04mp3 },
    { name: '05 POLITIK', url: lina05mp3 },
  ],
};
