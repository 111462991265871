/* eslint-disable max-len */
const ratio = 10.147 / 8.099;

function PlayIcon({ color = '#fff', size = 10 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size * ratio} height={size} viewBox="0 0 10.147 8.099">
      <g id="Groupe_13" data-name="Groupe 13" transform="translate(-241.603 -1177.55)">
        <path id="Tracé_17" data-name="Tracé 17" d="M-6793.917,14938.689v8.1" transform="translate(7036.27 -13761.14)" fill="none" stroke={color} strokeWidth="1.5" />
        <path id="Polygone_15" data-name="Polygone 15" d="M4.049,0,8.1,6.633H0Z" transform="translate(251.75 1177.551) rotate(90)" fill={color} />
        <path id="Tracé_18" data-name="Tracé 18" d="M-6793.917,14938.689v8.1" transform="translate(7036.27 -13761.14)" fill="none" stroke={color} strokeWidth="1.5" />
        <path id="Polygone_16" data-name="Polygone 16" d="M4.049,0,8.1,6.633H0Z" transform="translate(251.75 1177.551) rotate(90)" fill={color} />
      </g>
    </svg>
  );
}

export default PlayIcon;
