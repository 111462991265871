/* eslint-disable no-param-reassign */
import ReactPlayer from 'react-player';

import { useEffect, useState } from 'react';
import { Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import videoFile from '../assets/video.webm';
import videoThumbnail from '../assets/video_thumbnail.webp';

function VideoPage() {
  const [playing, setPlaying] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const [hasPlayed, setHasPlayed] = useState(false);
  const [ratio, setRatio] = useState(null);

  // thank you chatgpt
  useEffect(() => {
    const video = document.createElement('video');
    const handleMetadata = () => {
      setRatio(video.videoWidth / video.videoHeight);
      URL.revokeObjectURL(video.src);
      video.remove();
    };
    video.addEventListener('loadedmetadata', handleMetadata);
    video.src = videoFile;
    video.style.display = 'none';
    document.body.appendChild(video);

    return () => {
      video.removeEventListener('loadedmetadata', handleMetadata);
      if (document.body.contains(video)) {
        URL.revokeObjectURL(video.src);
        video.remove();
      }
    };
  }, [videoFile]);

  // set color to header links
  useEffect(() => {
    // on mount
    const els = document.querySelectorAll('#header .adapt-color');
    els.forEach((el) => { el.style.color = 'white'; });
    // on unmount
    return () => {
      els.forEach((el) => { el.style.color = 'black'; });
    };
  });

  return (
    <div style={{
      height: '100vh',
      width: '100vw',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      position: 'fixed',
      top: 0,
      left: 0,
      background: 'black',
    }}
    >
      <div style={{
        position: 'absolute',
        height: 60,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        left: 26,
        top: 208 + 52,
        zIndex: 20,
      }}
      >
        {!fullscreen
          ? (
            <Button
              variant="video"
              onClick={() => {
                setFullscreen(true);
              }}
            >
              FULLSCREEN
            </Button>
          ) : (
            <IconButton
              onClick={() => {
                setFullscreen(false);
              }}
            >
              <CloseIcon style={{ color: 'white' }} />
            </IconButton>
          )}
      </div>
      <div style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        position: 'absolute',
        top: 0,
        left: 0,
      }}
      >
        <Button
          variant="video"
          onClick={() => {
            setPlaying(!playing);
            setHasPlayed(true);
          }}
        >
          {!playing ? 'play' : 'pause'}
        </Button>
      </div>
      <div style={{ pointerEvents: 'none', zIndex: -1 }}>
        {ratio && (
        <ReactPlayer
          url={videoFile}
          controls={false}
          loop
          playing={playing}
          width={`${fullscreen ? 110 : 60}vw`}
          height={fullscreen ? '110vh' : `${60 / ratio}vw`}
            // width={fullscreen ? '100vw' : '60vw'}
            // height={fullscreen ? '200vh' : null}
          light={!hasPlayed && videoThumbnail}
              // eslint-disable-next-line react/jsx-no-useless-fragment
          playIcon={<></>}
        />
        )}
      </div>
    </div>
  );
}

export default VideoPage;
