import { Grid } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

function HeaderMobile() {
  const location = useLocation();
  return (
    <Grid container style={{ position: 'absolute', zIndex: 10000 }}>
      <Grid
        item
        xs={6}
        style={{
          fontFamily: 'EduDiatype-Mono-Regular',
          textTransform: 'uppercase',
          fontSize: '13px',
          padding: '20px',
        }}
      >
        <Link to="/">
          VERGISSMEIN-Nicht
        </Link>
      </Grid>
      <Grid
        item
        xs={3}
        style={{
          fontFamily:
          'EduDiatype-Mono-Regular',
          textTransform: 'uppercase',
          fontSize: '13px',
          padding: '20px',
          textAlign: 'center',
        }}
      >
        {location.pathname === '/projekt' && (
        <Link to="/">
          Close
        </Link>
        )}
        {location.pathname !== '/projekt' && (
        <Link to="/projekt">
          Projekt
        </Link>
        )}
      </Grid>
      <Grid
        item
        xs={3}
        style={{
          fontFamily: 'EduDiatype-Mono-Regular',
          textTransform: 'uppercase',
          fontSize: '13px',
          padding: '20px',
          textAlign: 'right',
        }}
      >
        {location.pathname === '/kontakt' && (
        <Link to="/">
          Close
        </Link>
        )}
        {location.pathname !== '/kontakt' && (
        <Link to="/kontakt">
          Kontakt
        </Link>
        )}
      </Grid>

    </Grid>
  );
}

export default HeaderMobile;
