import { Typography } from '@mui/material';

/* eslint-disable max-len */
export default
  <span>
    {`01 Heimarbeit
Louises Tochter Bernadette erinnert sich lebhaft an die Zeit, als ihre Mutter von zu Hause aus arbeitete. Es war eine andere Ära, in der Kinderbetreuung nicht wirklich verbreitet war. Ausserdem arbeitete Bernadettes Vater nicht und deshalb war Louise diejenige, die das Geld für die ganze Familie verdienen musste. Bernadette erinnert sich, wie ihre Mutter frühmorgens um 4 Uhr aufstand um zu arbeiten, und erst um Mitternacht ins Bett ging.

Louise arbeitete manchmal auch lange Stunden, um Ware rechtzeitig für den nächsten Morgen zu liefern. Sie stellte die Sachen um 17 Uhr bereit, danach wurden diese abgeholt und sofort durch neue ersetzt. Bernadette bewundert die immense Arbeitsleistung ihrer Mutter und erkennt, dass ihre Demenzerkrankung wahrscheinlich dazu führt, dass sie diese Momente jetzt immer wieder wiederholt.

Louise beschränkte sich jedoch nicht nur auf Heimarbeit. Sie arbeitete auch in den umliegenden Blöcken, indem sie die hauswirtschaftlichen Dienste übernahm. Dazu gehörte das Wischen in den Dominoblöcken sowie das regelmässige Putzen der Treppenhäuser jeden Freitag oder Samstag. Die Kinder mussten sogar im Winter um 5 Uhr morgens aufstehen, um den Schnee weg zu schaufeln, damit alles frei war, wenn die anderen ihren Tag begannen.

Die Kinder unterstützten ihre Mutter, nach den erledigten Hausaufgaben, indem sie bei der Heimarbeit mitwirkten. Sie kümmerten sich vor allem um die Ausfertigungen, das Verpacken und Zählen der Waren. Die Sachen wurden meistens im Dutzend oder manchmal auch im Zwei-Dutzend-Pack abgepackt. Die Produkte wurden dann in den Fernen Osten verkauft, darunter Stickereien, die voneinander getrennt werden mussten. Es handelte sich um Bordüren`}
    <sup>
      <a href="#footnote_louise_1">1</a>
    </sup>
    {`, die an Pyjamas genäht wurden. Louise kontrollierte sie auf Fehler, legte sie ordentlich hin, entfernte die Fehler und verpackte sie in Säcke.

Anschliessend brachte Louise die verpackten Waren persönlich nach St. Gallen, da Abtwil, wo sie damals wohnten, eine schlechte Anbindung an öffentliche Verkehrsmittel hatte.
Sie mussten oft mit den Schachteln über die Sitter nach Stocken laufen.

Louise hätte auch vor Ort bei Bischoff Textil arbeiten können, entschied sich jedoch dagegen, um bei ihren Kindern zuhause zu sein. Sie wollte da sein, wenn sie von der Schule nach Hause kamen, und arbeitete deshalb von zu Hause aus. Sie transportierte die Waren auch persönlich nach St. Gallen zurück, da es keinen Lieferservice gab und Abtwil für die Unternehmen zu weit entfernt war.

Louise begann 1960 bei Bischoff Textil zu arbeiten, vermutlich aufgrund der Verbindung zu ihrem Schwiegervater, der eine Stickerei hatte.
In Zeiten der Not bat sie einmal einen Pfarrer um etwas Geld, worauf er entgegnete, ob sie schon einmal in Erwägung gezogen hätte zu arbeiten…

Louise erinnert sich daran, wie sie kürzlich über die Frauen nachgedacht hatte, die heutzutage viel zu viel jammern. Für sie persönlich hätte das Jammern nichts gebracht, aber sie hatten es auf ihre eigene Art und Weise geschafft.
Sie arbeitete lange Zeit für die Unternehmen Bischoff Textil und Forster Rohner, bis sie schliesslich nicht mehr konnte.

Bernadette erklärt, dass sie und ihr Bruder zwar nicht selbst gestickt haben, aber bei einfacheren Aufgaben mitgeholfen haben. Sie schnitten Materialien aus und verpackten sie. Eine spezielle Methode, die sie entwickelt hatten, war das Trennen der Stickereien. Anstatt eine Schere zu verwenden, legten sie ein Holzbrett hin, an dem der Stoff gut haften konnte. Dann griffen sie zu einem scharfen Messer und konnten viel schneller und genauer arbeiten als mit einer Schere. Heutzutage werden solche Aufgaben maschinell erledigt, während sie früher den Heimarbeiterinnen übertragen wurden.

Louise erinnert sich daran, dass sie zwischendurch auch bei verschiedenen Familien geputzt hat, kann sich jedoch nicht an alle Einzelheiten erinnern.

Die Erfahrungen von Louise und Bernadette verdeutlichen den hohen Einsatz und die Anstrengungen, die Louise unternahm, um für ihre Familie zu sorgen.
Obwohl es harte Zeiten waren, haben sie gemeinsam die Herausforderungen bewältigt.


03 Jugendzeit
Bernadette berichtet, dass ihre Mutter Auto fahren konnte. In ihrer Umgebung war sie das erste Mädchen, das einen Führerschein besass. Die anderen fragten sie oft, ob sie mit ihr mitfahren könnten, wenn sie beispielsweise nach Basel mussten. Louise war bereit, ihnen diesen Gefallen zu tun. Bernadette erwähnt auch, dass ihre Mutter früher in der Gastronomie gearbeitet hat.
Louise erklärt, dass sie dort gearbeitet hat, wo sie Geld verdienen konnte, aber sie betont, dass sie niemals auf illegale Wege wie Prostitution zurückgegriffen hat.


04 Familie
Bernadette erzählt, dass die Ehe ihrer Eltern leider geprägt war und es Louise damals nicht möglich war, sich scheiden zu lassen. Obwohl ihre Mutter sich von ihrem gewalttätigen Ehemann trennen wollte, zwang sie der Anwalt dazu, zu ihm zurückzukehren. Infolgedessen war Louise gezwungen, ständig zu arbeiten. Die Heimarbeit bot ihr die Möglichkeit, ein Einkommen zu erzielen, obwohl sie keine formale Ausbildung oder Lehre absolviert hatte, was zu dieser Zeit auch kaum möglich war.

Bernadette und ihr Bruder waren bereits in jungen Jahren daran gewöhnt, bei der Arbeit zu helfen. Sie nahmen an einfachen Aufgaben teil, wie das Stapeln und Zählen von Gegenständen, oft bis zu einem Dutzend. Die Kinder waren bereit, ihren Beitrag zu leisten, um das Existenzminimum der Familie zu sichern. Sie halfen beispielsweise gerne dabei, den Rasen zu mähen und unterstützten bei anderen hauswirtschaftlichen Aufgaben.

Louise bestätigt, dass die Kinder früh angefangen haben zu helfen, sobald sie alt genug waren, um einfache Aufgaben zu erledigen. Sie erinnert sich daran, dass die Kinder nach der Schule ihre Hausaufgaben erledigten und dann für eine bestimmte Zeit mithalfen, bevor sie spielen oder nach draussen gehen durften. Bernadette empfindet diese Erinnerungen nicht als etwas Negatives, sondern als einen Teil ihres Lebens, der zwar finanziell begrenzt, aber dennoch normal war. Sie waren sich bewusst, dass ihre Unterstützung notwendig war, um genug Geld für den Einkauf zu haben. Obwohl das Leben reduziert war, erkannte Bernadette das volle Ausmass der Situation erst später im Verlauf ihres Lebens.
`}
    <br />
    <Typography variant="text-italic" id="footnote_louise_1">
      1. Bordüre: Eine Bordüre ist ein dekoratives Element, das entlang des Randes eines Stoffes oder einer Oberfläche verläuft und oft wiederholte Muster, Motive oder Designs enthält. Sie wird häufig verwendet, um Kleidungsstücke, Heimtextilien oder andere Gegenstände zu verzieren und ihnen einen zusätzlichen visuellen Reiz zu verleihen.
    </Typography>
  </span>;
