/* eslint-disable react/jsx-indent */

import { Typography } from '@mui/material';

/* eslint-disable max-len */
export default <span>
  {`01 Heimarbeit
Die Appenzeller Handstickerei ist eine jahrhundertealte Tradition, die von Generation zu Generation weitergegeben wurde. Eine der bekanntesten Farben, die in der Appenzeller Stickerei verwendet wird, ist das typische Hellblau, welches den Stoffen eine besondere Note verleiht. Schon in der 5. Primarschule lernte Hildegard Koch-Rusch von ihrer Mutter das Sticken, angefangen mit einfachen Tüchli`}
<sup>
<a href="#footnote_hilde_1">1</a>
</sup>
{` und Monogrammen. Doch sie gibt zu, dass sie nicht immer gerne gestickt hat. Erst als sie mit ihrer Mutter an der Stickbank sass und sie ihr half, wurde sie zum Sticken motiviert.
Als sie 17 Jahre alt war, bot sich ihr die Gelegenheit, in London zu sticken - ein aufregendes Erlebnis für sie.
Ein Broderiegeschäft`}
<sup>
<a href="#footnote_hilde_2">2</a>
</sup>
{` im Appenzell, welches ihr neue Ware brachte oder wo sie die Ware selbst abholte, gab ihr Aufträge für die Heimarbeit. Oft gab es noch eine Aufgabe die schnell erledigt werden musste, wie beispielsweise 12 Schnupftücher, die sie dann zu Hause bearbeitete und wieder zurückbrachte - eine Möglichkeit, um ein paar Franken dazu zu verdienen.
Im Appenzell gab es zu jener Zeit diverse Broderiegeschäfte, die je nach Auftrag spezifisch nach Handstickereien anfragten. Früher gab es Frauen, die sich auf verschiedene Bereiche spezialisiert hatten, wie zum Beispiel das sogenannte "Platten”. “Platten” war eine spezifische Stichart die grossteils verwendet wurde um die Motive zu sticken. Danach wurde das Produkt an das Broderiegeschäft zurückgegeben, welches die Stoffe an die "Hölerin“ weitergab. Diese Frau vervollständigte den Stickprozess und gab das Werkstück an die "Spitzlifrau" weiter, welche das feine Spitzenmuster auf das Werkstück stickte. So durchlief das Produkt drei verschiedene Hände, bevor es fertiggestellt war.
Durch diese Arbeitsteilung konnte die Perfektion der Appenzeller Handstickerei erreicht werden, die bis heute beeindruckt und fasziniert. Die Arbeit der Handstickerinnen hat sich im Laufe der Jahre weiterentwickelt, jedoch bleibt die traditionelle Handarbeit immer noch ein wichtiger Bestandteil der Appenzeller Kultur.

02 Stickerei
Hildegard erklärt, dass man den Faden immer wieder einfädeln muss, damit er nicht verloren geht. Und das ist gar nicht so einfach, denn die Nadel und der Faden sind so klein, dass man sie mit blossem Auge kaum sehen kann.
Den ersten Stich, den sie lernte war “plattät”`}
<sup>
<a href="#footnote_hilde_3">3</a>
</sup>
. Dabei zieht man den Faden zweimal durch den Stoff und bildet so eine Linie. Das sieht zwar einfach aus, erfordert aber eine ruhige Hand. Ein weiterer wichtiger Stich nennt sich “gehölt”
<sup>
<a href="#footnote_hilde_4">4</a>
</sup>
. Hier lässt man zwei Fäden stehen und zieht einen dritten durch, sodass ein kleines Loch entsteht. Diese Löcher werden später mit weiteren Stichen bestickt, um ein Muster zu bilden. Dann gibt es noch den Stich den man chromglätelet
<sup>
<a href="#footnote_hilde_5">5</a>
</sup>
{` nennt, der ähnlich wie “plattet" ist, aber etwas anders aussieht.
Die aufwändigste und feinste Technik, die Hildegard beherrscht, ist das “gespitzelt”`}
<sup>
<a href="#footnote_hilde_6">6</a>
</sup>
{`. Dabei erzeugt man mit einer Nadel ein kleines Loch im Stoff und spinnt dann einen Faden darin, um eine neue Fläche für ein Muster zu schaffen. Es braucht viel Übung und Geduld, um diese Technik zu beherrschen.
In ihrem vollen Ordner nimmt Hildegard einige bestickte Tücher hervor und zeigt auf zwei Stickereien die gespitzelt sind, aber mit unterschiedlichen Mustern. Es sei immer wieder erstaunlich, wie vielseitig die Stickerei sein kann und wie viele verschiedene Stiche es gibt, um einzigartige Kunstwerke zu schaffen.

03 Jugendzeit
Als sie in London war, gab es dort ein Einkaufszentrum mit einem riesigen Garten auf dem Dach. Die Kombination aus Pflanzen und Wasser faszinierte sie, denn sie hatte vorher noch nie so etwas gesehen. Auch den Buckingham Palast besuchte sie und war beeindruckt von der Architektur. Sie erinnert sich noch genau an das erste Mal, als sie ein Käsefondue in London gegessen habe. Viele Leute fanden es merkwürdig, dass ihr erstes Fondue-Erlebnis nicht in der Schweiz stattgefunden hat, aber zuhause kannten sie das schlicht nicht.

04 Familie
Als sie Kinder hatte, musste sie das Sticken zweitweise aufgeben. Die Aufgabe, sechs Kinder grosszuziehen, war einfach zu gross. Besonders nach dem schmerzlichen Verlusts ihres fünfjährigen Kindes bei einem Verkehrsunfall. Aber sie hielten als Familie zusammen und halfen sich gegenseitig.
Sie erinnert sich daran, wie sie im Wohnzimmer sass und stickte, da sie kein speziell eingerichtetes Zimmer dafür hatte. Sie nahm sich vor zu Sticken nachdem die Kinder im Bett waren, aber oft war sie dazu zu müde. Sie stickte daher nachmittags, bis es Zeit für das Abendessen war. Am Vormittag half sie oft ihrem Mann auf dem Bauernhof, indem sie das Milchgeschirr wusch oder ihm anderweitig zur Hand ging. Und wenn die Kleinen schliefen und die Älteren in der Schule waren, konnte sie sich am Nachmittag mal hinsetzen und sticken.

05 Politik
In der Politik hatte sie anfangs nichts für das Frauenstimmrecht`}
<sup>
<a href="#footnote_hilde_7">7</a>
</sup>
{` übrig, die Männer hätten es ja allen Recht gemacht. Bei ihr zuhause haben sie darüber diskutiert und sie erzählt, dass sie zuhause das Sagen hatte, während ihr Mann das Sagen ausserhalb hatte. Sie hat sich in dieser Rollenverteilung gar nicht benachteiligt gefühlt. Aber dann kamen die Jungen, die eine Änderung des Gesetzes wollten, und auch fremde Mütter und Väter, die das System und den Appenzeller Brauchtum nicht kannten. Sie waren damals wie ein Urvölkchen, zufrieden mit dem, was sie hatten. Die Frauen haben sich um den Haushalt gekümmert und die Männer um die Säue und Kühe. Es gab in ihrer Ehe keine Trennung zwischen ihrem Stickerei-Verdienst und dem Bauernverdienst ihres Mannes. Sie besassen nur eine Kasse und wenn es mal nicht so viel zu tun gab, haben sie halt mehr gearbeitet, um etwas mehr Geld zu haben. Arm wären sie nicht gewesen, aber auch nicht reich, sondern irgendwo in der Mitte. Das selbstverdiente Geld von den Stickereien konnte sie behalten und mal wieder etwas für sich selbst oder die Kinder kaufen. Flüssiges Geld war nicht viel vorhanden, aber sie haben sich trotzdem immer irgendwie durchgeschlagen.
`}
<br />
<Typography variant="text-italic" id="footnote_hilde_1">
1. Tüchli: Ein kleines Stück Stoff, das traditionell in der Schweiz bestickt wird. Die Stickerei auf dem Tüchli ist oft sehr filigran und detailreich und zeigt verschiedene Muster und Motive.
</Typography>
<br />
<br />
<Typography variant="text-italic" id="footnote_hilde_2">
2. Broderiegeschäft: Ist ein Geschäft, das auf den Verkauf von Stickereibedarf, sowie auf die Anfertigung von Stickereien spezialisiert ist.
</Typography>
<br />
<br />
<Typography variant="text-italic" id="footnote_hilde_3">
3. plattet: Der Plattstich ist eine traditionelle Sticktechnik. Dabei wird ein Stickfaden durch den Stoff gezogen und mit einem kurzen Stich festgenäht, wobei der Faden horizontal auf der Oberfläche des Stoffes verläuft. Der Plattstich wird oft für flächige Muster und Konturen verwendet und gilt als eine der wichtigsten Techniken in der Appenzeller Handstickerei.
</Typography>
<br />
<br />
<Typography variant="text-italic" id="footnote_hilde_4">
4. gehölt: Der Hohlstich ist eine Sticktechnik, bei der Fäden auf der Stoffoberfläche parallel zum Fadenverlauf gezogen und anschliessend mit kleinen Stichen fixiert werden. Dadurch entsteht eine hohle, reliefartige Wirkung. Der Hohlstich wird häufig für Blütenblätter und andere zarte Elemente in der Stickerei verwendet.
</Typography>
<br />
<br />
<Typography variant="text-italic" id="footnote_hilde_5">
5. chromglätelet: Chromglätelet ist eine spezielle Sticktechnik, bei der durch das Abdecken von Stoffbereichen mit Stickfäden ein Glanzeffekt erzielt wird. Hierbei wird ein dicker Faden mit Glanzfäden umwickelt und dann auf den Stoff gestickt. Durch das Übersticken mit weiteren Fäden entsteht eine ebene Fläche mit glänzender Oberfläche, die einen besonderen optischen Effekt erzeugt.
</Typography>
<br />
<br />
<Typography variant="text-italic" id="footnote_hilde_6">
6. gespitzelt: Gespitzelt ist eine Sticktechnik, dabei wird mit einer feinen Nadel ein kleines Loch in den Stoff gestochen, um daraufhin einen Faden hindurchzuführen und ein Muster zu erstellen.
</Typography>
<br />
<br />
<Typography variant="text-italic" id="footnote_hilde_7">
7. Frauenstimmrecht: Der Kanton Appenzell Innerrhoden war der letzte Kanton in der Schweiz, der das Frauenstimmrecht eingeführt hat. Erst 1990 wurde das Stimmrecht für Frauen auf kantonaler Ebene eingeführt, nachdem der Schweizerische Bundesgerichtshof 1989 entschieden hatte, dass das Verbot des Frauenstimmrechts im Kanton Appenzell Innerrhoden gegen die Bundesverfassung verstösst. Trotz des langen Widerstands gegen das Frauenstimmrecht hat sich der Kanton Appenzell Innerrhoden in den letzten Jahren in Bezug auf die Geschlechtergleichheit verbessert und hat jetzt eine höhere Frauenquote in der Politik als viele andere Kantone in der Schweiz.
</Typography>
               </span>;
