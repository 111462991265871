/* eslint-disable no-param-reassign */
import { Button, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import slug from 'slug';
import SliderImage from './Image';

function PersonalitySummary({ people }) {
  // eslint-disable-next-line no-unused-vars
  const [personKey, setPersonKey] = useState(null);
  const [personType, setPersonType] = useState(null);
  const onMouseEnter = (key, type) => {
    setPersonKey(key);
    setPersonType(type);
  };
  const onMouseLeave = () => {
    setPersonKey(null);
    setPersonType(null);
  };

  const url = people[personKey]?.[personType];

  return (
    <Grid
      className="personality-slide"
      container
      sx={{
        height: '100vh', width: '100%', position: 'relative',
      }}
    >
      <div style={{ marginTop: '20vh', width: '100%', padding: 26 }}>
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          <div style={{ width: 238 - 26 }}>
            <Typography variant="text-bold" sx={{ color: '#9D9D9D', fontSize: '16px' }}>Name:</Typography>
          </div>
          <div style={{
            flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start',
          }}
          >
            {people.map((p, key) => (
              <Button
                key={p.name}
                variant="personality-info"
                href={`#${slug(p.name)}`}
                sx={{ fontSize: 16, lineHeight: '16px', marginBottom: '4px' }}
                onMouseEnter={() => onMouseEnter(key, 'hoverName')}
                onMouseLeave={() => onMouseLeave(key)}
              >
                {p.name}
              </Button>
            ))}
          </div>
          <div style={{ width: 120 }}>
            <Typography variant="text-bold" sx={{ color: '#9D9D9D', fontSize: '16px' }}>Heimat:</Typography>
          </div>
          <div style={{
            width: 160, display: 'flex', flexDirection: 'column', alignItems: 'flex-start',
          }}
          >
            {people.map((p, key) => (
              <Button
                key={p.name}
                variant="personality-info"
                href={`#${slug(p.name)}`}
                sx={{ fontSize: 16, lineHeight: '16px', marginBottom: '4px' }}
                onMouseEnter={() => onMouseEnter(key, 'hoverOrt')}
                onMouseLeave={() => onMouseLeave(key)}
              >
                {p.home}
              </Button>
            ))}
          </div>
          <div style={{ width: 120 }}>
            <Typography variant="text-bold" sx={{ color: '#9D9D9D' }}>Kanton:</Typography>
          </div>
          <div style={{
            width: 120, display: 'flex', flexDirection: 'column', alignItems: 'flex-start',
          }}
          >
            {people.map((p, key) => (
              <Button
                key={p.name}
                variant="personality-info"
                href={`#${slug(p.name)}`}
                sx={{ fontSize: 16, lineHeight: '16px', marginBottom: '4px' }}
                onMouseEnter={() => onMouseEnter(key, 'hoverOrt')}
                onMouseLeave={() => onMouseLeave(key)}
              >
                {p.canton}
              </Button>
            ))}
          </div>
        </div>

        <div style={{ marginTop: '20vh', display: 'flex', alignItems: 'flex-start' }}>
          <div style={{ width: 238 - 26 }}>
            <Typography variant="text-bold" sx={{ color: '#9D9D9D' }}>Jahrgang:</Typography>
          </div>
          <div style={{
            flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start',
          }}
          >
            {people.map((p, key) => (
              <Button
                key={p.name}
                variant="personality-info"
                href={`#${slug(p.name)}`}
                sx={{ fontSize: 16, lineHeight: '16px', marginBottom: '4px' }}
                onMouseEnter={() => onMouseEnter(key, 'hoverName')}
                onMouseLeave={() => onMouseLeave(key)}
              >
                {p.year}
              </Button>
            ))}
          </div>
        </div>
      </div>
      {personKey !== null && (
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100vh',
          zIndex: -1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        >
          <SliderImage url={url} smaller />
        </div>
      )}
    </Grid>
  );
}

export default PersonalitySummary;
