import mp3 from './Louise_mobile.mp3';
import lina00mp3 from './00_Prolog_Louise_01.mp3';
import lina01mp3 from './01_Heimarbeit.mp3';
import lina03mp3 from './03_Jugendzeit.mp3';
import lina04mp3 from './04_Familie.mp3';

export default {
  mp3,
  mp3Chapters: [
    { name: '00 PROLOG', url: lina00mp3 },
    { name: '01 HEIMARBEIT', url: lina01mp3 },
    { name: '03 JUGENDZEIT', url: lina03mp3 },
    { name: '04 FAMILIE', url: lina04mp3 },
  ],
};
