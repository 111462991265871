function RightArrow({ style = {}, className }) {
  return (
    <span
      style={{
        fontSize: '11px', fontFamily: 'SegoeUI, Segoe UI', letterSpacing: '0.05em', ...style,
      }}
      className={className}
    >
      &gt;&gt;
    </span>
  );
}

export default RightArrow;
