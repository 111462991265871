/* eslint-disable max-len */

const ratio = 7.602 / 10.899;
function PauseIcon({ color = '#fff', size = 10 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size * ratio} height={size} viewBox="0 0 7.602 10.899">
      <path id="Tracé_12" data-name="Tracé 12" d="M-6793.917,14938.69v10.9" transform="translate(6795.167 -14938.69)" fill="none" stroke={color} strokeWidth="2.5" />
      <path id="Tracé_13" data-name="Tracé 13" d="M-6793.917,14938.69v10.9" transform="translate(6800.268 -14938.69)" fill="none" stroke={color} strokeWidth="2.5" />
      <path id="Tracé_15" data-name="Tracé 15" d="M-6793.917,14938.69v10.9" transform="translate(6795.167 -14938.69)" fill="none" stroke={color} strokeWidth="2.5" />
      <path id="Tracé_16" data-name="Tracé 16" d="M-6793.917,14938.69v10.9" transform="translate(6800.268 -14938.69)" fill="none" stroke={color} strokeWidth="2.5" />
    </svg>
  );
}

export default PauseIcon;
